.report_issue {
    display: flex;
    justify-content: center;
    // align-items: center;
    padding: 50px 0;
    background: #fff;
    min-height: 100vh;
  
    .content {
      border-bottom: 1px solid #ade3f3;
      padding-bottom: 20px;
      .heading {
        margin-bottom: 8px;
        h1 {
          font-size: 18px;
          font-weight: 600;
        }
      }
      .form-group {
        margin: 10px 0;
        position: relative;
        .form-select,
        .form-control {
          background: #f7f8fc;
          font-size: 15px;
          border-radius: 10px;
          border-color: #d6edf4;
          color: #000;
          font-weight: 500;
          padding: 10px;
          min-height: 40px;
          &::placeholder {
            color: #000000;
          }
          span {
            color: #000;
            position:static;
          }
        }
        
        .textArea {
          border-radius: 12px;
        }
        &.attachInput{
          border: 1px solid #d6edf4;
          border-radius: 10px;
          padding: 0 10px;
          background: #f7f8fc;

          .attach_input_icon{
            position: relative;
            cursor: pointer;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 6px 0;
            span {
              color: #000;
              font-size: 15px;
              font-weight: 500;
              position: static;
            }
          }
          .form-control{
            border: none;
            padding: 5px 0 0;
            min-height: auto;
          }
          .upload_img_box{
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin: 10px 0;
            .image-item{
              margin-right: 5px;
              position: relative;
              margin-bottom: 5px;
              border: 1px solid #000;
              border-radius: 5px;
              img{
                width: 60px;
                border-radius: 5px;
                height: 40px;
                object-fit: cover;
                padding: 5px;
              }
              .image-item__btn-wrapper{
                position: absolute;
                top: -6px;
                right: -2px;
                background: #449ea7;
                color: #fff;
                border-radius: 50%;
                width: 15px;
                height: 15px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                button{
                  color: #fff;
                }
              }
            }
          }
          .attachIcon{
            position: absolute;
            right: 15px;
            top: 9px;
            line-height: 0;
          }
        }
      }
      .addMore {
        text-align: right;
        margin-top: 15px;
        .submitBtn {
          display: inline-block;
          margin: 0 auto;
          background: #000;
          color: #fff;
          padding: 10px 55px;
          font-size: 14px;
          border-radius: 10px;
        }
      }
    }
    
    .ticket_box{
      padding-top: 25px;
      h6{
        font-size: 17px;
        color: #000;
        font-weight: 600;
      }
      .border-1{
        margin-top: 15px;
        border: 1px solid #d6edf4;
        border-radius: 8px;
        .table{
          text-align: left;
          margin: 0;
          thead{
            tr{
              border-width: 0;
            }
            th{
              font-size: 14px;
              font-weight: 700;
            }
          }
          tbody{
            border: 1px solid #d6edf4;
            td{
              font-size: 14px;
              font-weight: 500;
            }
            .attach_docs{
              display: flex;
              flex-wrap: wrap;
              img{
                width: 40px;
                height: 30px;
                min-width: 40px;
                border: 1px solid #d6edf4;
                border-radius: 6px;
                margin-right: 5px;
              }
              span{
                display: inline-block;
                width: 100%;
                margin-left: 5px;
                color: #66bbc0;
                font-size: 14px;
                font-weight: 600;
              }
            }
            
          }
          a{
            vertical-align: -webkit-baseline-middle;
            color: #3baaaf;
            font-weight: 500;
            font-size: 13px;
          }
        }
      }
    }
  }
  @media (max-width:991px) {
    .report_issue{
      .content{
    .form-group{
      &.attachInput{
          ul{
            flex-wrap: nowrap;
            li{
              img{
                width: 50px;
              }
            }
          }
      }
    }
  }
  } 
  }

  @media (max-width: 576px)
  {
    .report_issue {
        .content {
          .addMore{
            text-align: center;
          }
            .historyBox{
                padding: 25px 35px;
                h6{
                    font-size: 15px;
                }
                .MessageBox {
                    .senderBox,.receiverBox{
                        padding: 8px;
                    }
                }
                .textMessage{
                    margin-top: 35px;
                }
            }
            .leftBox{
                .user_chats{
                    height: 305px;
                }
            }
        }
    }
  }

  .Drop_images {
    background-color: #45aaaf;
    color: #ffffff;
    border-radius: 3px;
    padding: 5px;
  
  }
  
  .remove_images {
    background-color: rgba(222,8,9, 0.7);
    color: #ffffff;
    border-radius: 3px;
    padding: 5px;
  
  }