.work_location {
  padding: 25px 0;
  background: #fff;
  min-height: calc(100vh - 62px);

  .addBtn {
    text-align: center;
    float: right;
    max-width: 85px;
    width: 100%;
    a {
      background: #000;
      color: #fff !important;
      border-radius: 6px;
      padding: 6px;
      font-size: 15px;
      width: 100%;
    }
  }

  .awrded_job_title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #000;
    font-weight: 600;
    margin-bottom: 0;
  }

  .location_title {
    text-align: left;
    margin-bottom: 15px;
    .backBtn {
      display: inline-block;
      color: #000000;
      font-weight: 900;
      position: absolute;
      left: 0px;
      top: 2px;
      span{
          font-size: 20px;
      }
    }
    h4 {
      color: #000000;
      font-weight: 700;
      font-size: 22px;
      color: #000;
      margin-bottom: 0;
    }
  }

  .left_sidebar{
    border: 1px solid #d6edf4;
    border-radius: 10px;
    text-align: left;
    h5{
      font-size: 16px;
      color: #000;
      font-weight: 600;
      border-bottom: 1px solid #d6edf4;
      padding: 8px 10px;
      margin-bottom: 0;
    }
    .nav_box{
      height: 556px;
      overflow: auto;
      .nav-link{
        text-align: left;
        padding: 0px 10px;
        font-weight: 500;
        width: 100%;
        border-radius: 0;
        color: #000;
        font-size: 14px;
        border-bottom: 1px solid #d6edf4;
        span{
          display: block;
          font-size: 13px;
          color: #727272;
          // border-bottom: 1px solid #d6edf4;
          padding: 6px 15px 6px 0;
          position: relative;
          a{
            position: absolute;
            right: 0;
            top: 6px;
          }
        }
        .business_name{
          font-weight: 700;
          color: #429aa4;
        }
        .action_btn{
          text-align: right;
          i{
            color: #429aa4;
          }
        }
        .hst_number{
          border-bottom: 0;
        }
        &.active{
          background: #f0f3ff;
          color: #000;
        }
      }
    }

    .nav{
      .nav-link{
        &:last-child{
          border-bottom: none;
        }
      }
    }
  }

  .my_unitBox,.my_locaBox{
    border: 1px solid #d6edf4;
    border-radius: 10px;
  }

  .title_box{
    padding: 8px;
  }

  .content ,.reach_box{
    height: 542px;
    overflow: auto;
    padding: 0px 8px;
    .unit_box{
      padding: 0px 5px;
      border: 1px solid #d6edf4;
      border-radius: 10px;
      margin-top: 10px;
      h6{
        font-size: 14px;
        color: #727272;
        margin-bottom: 0px;
        // border-bottom: 1px solid #d6edf4;
        padding: 7px 0;
        display: flex;
        word-break: break-all;
        span{
          min-width: 125px;
          font-weight: 500;
          color: #429aa4;
        }
      }
      .attach_docs{
        display: flex;
        padding: 5px 0;
        border-bottom: 1px solid #d6edf4;
        h6{
          border-bottom: none;
          color: #000;
          font-weight: 600;
          min-width: 125px;
        }

        .unit_outerBox{
          .unit_contBox{
            padding-left: 10px;
            width: 100%;
            margin-top: 10px;
            p{
              font-size: 14px;
              color: #727272;
              font-weight: 500;
            }
            .pdfFile {
              display: flex;
              align-items: center;
              color: #10979f;

              span{
                word-break: break-all;
              }

              img {
                margin-left: 5px;
              }
            }

            &:first-child{
              margin-top: 0;
            }
          }
        }
        
      }
      .action_btn{
        display: flex;
        align-items: center;
        h6{
          border-bottom: none;
        }
        .del_btn{
          display: inline-block;
          font-size: 12px;
          margin-left: 10px;
          i{
            color: #ff0907;
          }
        }
        .edit_btn{
          display: inline-block;
          font-size: 12px;
          margin-left: 10px;
          i{
            color: #31a3ae;
          }
        }
      }
    }
  }
}

@media (max-width: 991px){
  .work_location {
    .awrded_job_title{
      font-size: 15px;
    }
    .addBtn{
      max-width: 50px;
    }
    .content,.reach_box {
      .unit_box{
        h6{
          font-size: 13px;
          span{
            min-width: 105px;
            max-width: 105px;
          }
        }
        .attach_docs{
          flex-direction: column;
          h6{
            min-width: 55px;
            max-width: 55px;
            span{
              min-width: auto;
              max-width: none;
            }
          }
          .unit_outerBox{
            .unit_contBox {
              padding-left: 0;
              p{
                font-size: 13px;
                span{
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}

  @media (max-width: 576px){
    .work_location{
      .my_unitBox{
        margin: 15px 0;
      }
    }

    .business_modal{
      .modal-dialog{
        max-width: none !important;
      }
    }
  }
  


  .business_modal{
    .modal-dialog{
      max-width: 335px;
    }
    .modal-content{
      box-shadow: 0px 4px 7px 0px rgba(189, 219, 249, 0.36);
    }
    .modal-header{
      justify-content: center;
      border-bottom: none;
      padding-bottom: 0;
      .modal-title{
        font-size: 17px;
        font-weight: 600;
      }
      .btn-close{
        margin: 0;
        position: absolute;
        right: 0;
        top: 0;
        font-size: 18px;
        z-index: 99999;
        line-height: 0;
        color: #000;
        background: none;
        &:focus{
          box-shadow: none;
        }
      }
    }
  
   .modal-body{
    .form-group{
      border-radius: 10px;
    }
   }
  
    .pay_submit_box{
      text-align: center;
      margin: 0 0 1rem;
      .pay_submit_btn{
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        max-width: 135px;
        width: 100%;
        text-align: center;
        padding: 8px 0px;
        background: #000;
        border-radius: 10px;
      }
    }
    
  }