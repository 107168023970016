.wallet_balance {
  padding: 35px 0;
  background: #fff;
  min-height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    border-radius: 15px;
    .withdrawInner {
      border-bottom: 1px solid #d6edf4;
      padding-bottom: 25px;
      .title{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        h5{
          font-size: 16px;
          font-weight: 600;
          color: #000;
          margin-bottom: 0;
          span{
            display: inline-block;
            color: #54b0b0;
            font-size: 22px;
          }
        }
        span{
          margin: 0 10px;
          display: inline-block;
        }
      }
      .submit_btn{
        text-align: center;
        margin-top: 15px;
        a{
          display: inline-block;
          max-width: 225px;
          width: 100%;
          margin-top: 8px;
          border: 1px solid #000;
          border-radius: 6px;
          padding: 10px 15px;
          font-size: 13px;
          background: #000;
          color: #fff;
          font-weight: 500;
        }
      }
    }

    .form-group {
      position: relative;
      margin: 8px 0;
      .form-control,
      .form-select {
        border-radius: 10px;
        background: #f7f8fc;
        border-color: #ddecf0;
        border-width: 2px;
        color: #292a2e;
        // padding-right: 35px;
        padding-right: 8px;
        min-height: 40px;
        &:focus {
          border-color: #919191;
        }

        &.amount_input{
          max-width: none !important;
        }
      }
      span {
        position: absolute;
        right: 15px;
        color: #676767;
      }
      .invalid-feedback{
        position: absolute;
      }
    }
    .transaction_box{
      padding-top: 10px;
      .border-1{
        margin-top: 15px;
        border: 1px solid #d6edf4;
        border-radius: 8px;
        .table{
          text-align: left;
          margin: 0;
          thead{
            tr{
              border-width: 0;
            }
            th{
              font-size: 14px;
              font-weight: 700;
            }
          }
          tbody{
            border: 1px solid #d6edf4;
            td{
              font-size: 14px;
              font-weight: 500;
            }
            .instant_text{
              color: #f63643;
            }
            .recive_text{
              color: #74b0bd;
            }
          }
          a{
            vertical-align: -webkit-baseline-middle;
            color: #000;
          }
        }
        .attach_docs{
          display: flex;
          img{
            width: 50px;
            height: 35px;
            min-width: 50px;
            border: 1px solid #d6edf4;
            border-radius: 6px;
          }
          span{
            display: inline-block;
            width: 100%;
            margin-left: 5px;
            color: #66bbc0;
            font-size: 14px;
            font-weight: 600;
          }
        }
        .action_btn{
          display: flex;
          justify-content: space-between;
          align-items: center;
          .del_btn{
            display: inline-block;
            color: #ff0907;
            font-size: 12px;
          }
          .edit_btn{
            color: #5a5a5a;
            display: inline-block;
            font-size: 12px;
          }
        }
      }
    }
  }
}
  
  @media (max-width: 576px){
    .wallet_balance {
      .content{
        padding: 0;
        .form-group{
          margin: 12px 0;
          .invalid-feedback{
            margin-bottom: 10px;
          }
        }
        .transaction_box{
          padding-top: 25px;
          .border-1{
            .table{
              thead{
                th{
                  padding: 4px;
                  word-spacing: 4px;
                }
              }
            }
          }
        }
        .tabBox{
          .nav{
            .nav-item{
              display: block;
              width: 100%;
              .nav-link{
                width: 100%;
                margin: 7px 0;
              }
            }
          }
          .tab-content {
            .tab-pane {
              .jobBox {
                .para {
                  margin: 5px 0;
                  .numBox {
                    p{
                      justify-content: flex-start;
                    }
                  }
                }
              }
            }
          }
          .jobBox{
            padding: 15px;
          }
        }
      }
    }
  }
  input,
    .StripeElement {
      display: block;
      margin: 10px 0 20px 0;
      max-width: 500px;
      padding: 10px 14px;
      font-size: 1em;
      font-family: 'Source Code Pro', monospace;
      box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
      border: 0;
      outline: 0;
      border-radius: 4px;
      background: white;
    }

    input::placeholder {
      color: #aab7c4;
    }

    input:focus,
    .StripeElement--focus {
      box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
      -webkit-transition: all 150ms ease;
      transition: all 150ms ease;
    }

    .StripeElement.IdealBankElement,
    .StripeElement.PaymentRequestButton {
      padding: 0;
    }