.job_rating {
  padding: 25px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  min-height: calc(100vh - 60px);

  .form-group {
    position: relative;
    margin: 8px 0;
    .form-control,.form-select {
      background-color: #fff;
      color: #a0a7b9;
      border-radius: 10px;
      border-color: #d6edf4;
      padding-right: 35px;
      font-size: 14px;
      min-height: 40px;
      font-weight: 400;
      &:focus {
        border-color: #d6edf4;
      }
      &::placeholder{
        color: #000;
      }
    }
    span {
      position: absolute;
      top: 40%;
      right: 15px;
      transform: translateY(-40%);
      color: #8296af;
    }
  }

  .left_sidebar{
    border: 1px solid #d6edf4;
    border-bottom: none;
    border-radius: 10px;
    text-align: left;
    h5{
      font-size: 17px;
      color: #000;
      font-weight: 600;
      border-bottom: 1px solid #d6edf4;
      padding: 8px 10px;
      margin-bottom: 0;
    }
    .nav_box{
      .nav-link{
        text-align: left;
        padding: 5px 10px;
        font-weight: 500;
        width: 100%;
        border-radius: 0;
        color: #000;
        font-size: 14px;
        border-bottom: 1px solid #d6edf4;
        span{
          display: block;
          color: #727272;
          font-size: 13px;
          display: flex;
          align-items: center;
          i{
            margin-right: 5px;
          }
        }
        &.active{
          background: #f7f8fc;
          color: #000;
        }
      }
    }
  }
  .content{
    border: 1px solid #d6edf4;
    border-radius: 10px;
    padding: 20px;
    .rating_box{
      h4{
        font-size: 18px;
        color: #000;
        font-weight: 600;
      }
      .date_para{
        display: flex;
        align-items: center;
        justify-content: end;
        font-size: 14px;
        font-weight: 500;
        color: #000;
        svg{
          margin-right: 5px;
        }
      }

      h6{
        font-size: 15px;
        font-weight: 600;
        color: #000;
        margin-bottom: 0;
      }

      .starRate_box{
        margin: 5px 0 15px;
        display: flex;
        align-items: center;
        i{
          color: #ffc921;
        }
      }
      .reason_box{
        border: 1px solid #d6edf4;
        border-radius: 10px;
        padding: 15px;
        p{
          font-size: 14px;
          color: #5b5b5b;
          font-weight: 400;
          margin-top: 10px;
        }
      }
    }
  }
}

@media (max-width: 1199px){
  .job_rating {
    .left_sidebar {
      .nav_box {
        .nav-link{
          span{
            align-items: baseline;
          }
        }
      }
    }
  }
}

@media (max-width: 576px){
  .job_rating {
    .left_sidebar {
      margin-bottom: 15px;
      .nav_box {
        .nav-link{
          span{
            align-items: baseline;
          }
        }
      }
    }
  }
}

@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";

#container {
  margin: 1rem;
}

.items {
  margin-bottom: 1rem;
}