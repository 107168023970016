.notification_Alert {
    padding: 35px 0;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 0px);
  
    .content {
      border-radius: 15px;
      background-color: #fff;
      .title {
        display: flex;
        align-items: center;
        position: relative;
        .backArrow {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          a {
            border: 0;
            outline: none;
            background: none;
            color: #000;
            font-weight: 600;
            i{
                color: #000;
            }
          }
        }
        .heading {
          width: 100%;
          text-align: center;
          h4{
            text-align: left;
            font-weight: 600;
            color: #000000;
            font-size: 18px;
        }
        }
      }
      .notifyBox{
          border: 1px solid #9ad8f1;
          border-radius: 15px;
          margin: 20px 0;
          padding: 10px;
          min-height: 115px;
          h5{
              font-size: 16px;
              color: #000;
              font-weight: 600;
              margin-bottom: 10px;
          }
          p{
              font-size: 14px;
              font-weight: 500;
              color: #000;
              margin-bottom: 0px;

          }
          .time{
              text-align: right;
              span{
                  display: inline-block;
                  font-size: 14px;
                  color: #a9a9a9;
                  font-weight: 500;
              }
          }
          &.confirmJob{
              .confirmBtn{
                  text-align: right;
                  a{
                      color: #24bcc9;
                      border-radius: 8px;
                      font-weight: 500;
                      font-size: 13px;
                      i{
                          color: #2f9696;
                          margin-left: 3px;
                      }
                  }
              }
              h6{
                  font-size: 15px;
                  font-weight: 600;
                  color: #000;
              }
              
              .time{
                  display: flex;
                  justify-content: space-between;
                  p{
                    font-size: 13px;
                    font-weight: 600;
                    color: #000;
                    display: flex;
                    align-items: center;
                    margin: 0px;
                    i{
                        margin-right: 3px;
                    }
                  }
                  span{
                    display: inline-block;
                    font-size: 14px;
                    color: #a9a9a9;
                    font-weight: 500;
                  }
              }
          }
      }
    }
  }

  @media (max-width: 576px){
    .notification_Alert{
        .content{
            .title{
                .heading{
                    h4{
                        font-size: 21px;
                    }
                }
                .backArrow{
                    top: 40%;
                    a{
                        .add-span{
                            display: none;
                        }
                    }
                }
            }
          .location_title{
              .backBtn{
                  font-size: 0px;
              }
          }  
            .notifyBox{
                padding: 15px;
                p{
                    font-size: 15px;
                }
                h5{
                    margin-bottom: 10px;
                }
                &.confirmJob{
                    p{
                        font-size: 14px;
                    }
                    .confirmBtn{
                        text-align: left;
                        margin: 20px 0;
                        a{
                        font-size: 14px;
                    }
                    }
                }
            }
        }
    }
  }
  
  