.page_404 {
    display: flex;
    justify-content: center;
    background: #fff;
    min-height: 100vh;
    padding: 85px 0 25px;
    .regForm {
      text-align: center;
      background: #fff;
      margin-top: 15px;

      h3{
        font-weight: 700;
        color: #183650;
        margin: 25px 0 10px;
      }

      p{
        font-size: 16px;
        color: #333333;
        font-weight: 500;
      }
      .nextBtn {
        margin: 20px 0 0;
        text-align: center;
        padding: 0 20px;
        a {
          display: inline-block;
          align-items: center;
          justify-content: center;
          background: #000;
          color: #fff;
          border-radius: 10px;
          padding: 12px 5px;
          font-size: 13px;
          max-width: 150px;
          width: 100%;
          span{
            margin-left: 5px;
          }
          i {
            color: #fff;
          }
        }
      }
    }
  }

  @media (max-width: 1199px){
     .page_404{
       .regForm{
         padding: 20px;
       }
     }
  }
  @media (max-width: 991px){
    .page_404{
      .regForm{
        padding: 25px;
      }
    }
  }
  @media (max-width: 576px){
    .page_404{
      .regForm{
        padding: 0;
      }
    }
  }