.registrationPage {
  padding: 100px 0 25px;
  display: flex;
  justify-content: center;
  // align-items: center;
  background: #fff;
  min-height: 100vh;
  .regForm {
    text-align: center;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    .regBox{
      border-bottom: none;
      position: relative;
      h4 {
        font-weight: 600;
        color: #000;
        text-align: center;
        font-size: 20px;
      }
      .editDetail {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        background: #000;
        border-radius: 5px;
        a {
          display: inline-block;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-weight: 500;
          font-size: 11px;
          padding: 5px 8px;
          span{
            margin-left: 5px;
          }
          i{
            color: #fff;
          }
        }
      }
    }
    .avatar{
      margin: 25px 0;
      padding: 0 25px 15px;
      border-bottom: 1px solid #ccc;
      .profileImg-Img {
        text-align: center;
        position: relative;
        width: 100px;
        height: 100px;
        margin: auto;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        margin-bottom: 25px;
        line-height: 80px;

        img{
          width: 75px;
          height: 75px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .profileImg{
        text-align: center;
        position: relative;
        width: 75px;
        height: 75px;
        margin: auto;
        border-radius: 50%;
        border: 2px solid #9ad8f1;
        display: flex;
        justify-content: center;
        margin-bottom: 25px;
        align-items: center;
        cursor: pointer;

        img{
          width: 75px;
          height: 75px;
          border-radius: 50%;
          object-fit: cover;
        }

        span{
            display: inline-block;
            color: #cecece;
            font-size: 35px;
            line-height: 0;
            cursor: pointer;
            .user_icon{
              font-size: 26px;
            }
        }
        .icon{
          position: absolute;
          right: -10px;
          bottom: 0px;
          .camIcon{
              border: 1px solid #898989;
              width: 30px;
              height: 30px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #000;
              cursor: pointer;
              label {
                cursor: pointer;
              }
            a{
                color: #5e5e5e;
                display: inline-block;
                font-size: 14px;
                line-height: 0;
                i{
                  font-size: 12px;
                  cursor: pointer;
                }
            }
          }
      }
    }
    h5{
      text-align: left;
      font-size: 16px;
      font-weight: 700;
      position: relative;
      &::after{
        content: '';
        position: absolute;
        width: 50%;
        height: 1px;
        background: #5e5e5e;
        display: inline-block;
        top: 50%;
        left: 50%;
        transform: translateY(-50%, -50%);
      }
    }
    }
    .identityBox{
      .identityTitle{
        position: relative;
        margin-bottom: 30px;
        top: 10px;
        h5{
          text-align: center;
          font-weight: 700;
          font-size: 16px;
          color: #000;
        }
        .editDetail{
          position: absolute;
          top: 0px;
          right: 0px;
          a{
            color: #484848;
            display: inline-block;
            padding: 0;
            text-align: right;
            span{
              // margin-right: 0 !important;
              i{
                font-size: 20px;
                color: #000;
              }
            }

            &:focus, &:focus-visible{
              outline: none;
              box-shadow: none;
            }
          }

          &.add-btn-box{
            position: static;
            text-align: right;
            margin-top: 10px;

            a{
              display: inline-flex;
              align-items: center;
              justify-content: flex-end;

              span{
                display: flex;
                align-items: center;
              }

              &:first-child:active{
                border-color: transparent;
              }
            }
          }
        }
      }
      .doc_box{
        display: flex;
        align-items: center;
        border: 1px solid #9ad8f1;
        padding: 10px 8px;
        border-radius: 10px;
        .content{
          width: 100%;
          margin-right: 10px;
          text-align: left;
          h6{
            margin-bottom: 0px;
            font-size: 14px;
          }
          span{
            font-size: 13px;
            color: #7e859f;
            font-weight: 600;
          }
        }
        img{
          width: 75px;
          min-width: 75px;
          height: 50px;
          border: 1px solid #f1f1f1;
          border-radius: 10px;
          overflow: hidden;
        }
      }
    }

    .nextBtn {
      margin: 30px 0 0;
      text-align: center;
      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #000;
        color: #fff;
        border-radius: 10px;
        padding: 8px 10px;
        width: 300px;
        font-size: 15px;
        span {
          margin-left: 5px;
        }
      }
    }
  }
}
@media (max-width: 576px){
  .registrationPage{
    padding: 90px 0px 25px;
    .regForm{
      .avatar{
        margin: 10px 0 15px;
        padding: 0 0px 15px;
      }
      .nextBtn{
        a{
          width: 100%;
        }
      }
      .identityBox{
        .identityTitle{
          h5{
            text-align: left;
          }
          .editDetail{
            top: 0;
            right: 0;
            a{
              .add-span{
                display: none;
              }
              span{
                i{
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
      .regBox {
        .editDetail{
          a{
            padding: 6px 12px;
            line-height: 0;
            span{
              display: none;
            }
          }
        }
      }
    }
  }
}

.inputFile{
  display: flex !important;
  justify-content: space-between;
  line-height: 35px !important;
  padding: 6px 15px !important;

  .selectFile{
    cursor: pointer;
  }
  
}
.invalid-feedback {
  display: block !important;
  width: 100%;
  margin-top: 3px !important;
  font-size: .875em;
  color: #dc3545;
  text-align: left;
}
.closeBtn {
  cursor: pointer !important;
  margin-left: 5px !important;
  line-height: 10px !important;
  // padding: 5px !important;
  float: right;
  position: relative;
  bottom: 35px;
  left: 20px;
}

.update_files{
  border: 1px solid;
  display: block;
  text-align: center;
  border-radius: 10px;
  background: #f7f8fc;
  border-color: #ddecf0;
  border-width: 2px;
  color: #292a2e;
  padding-right: 30px;
  min-height: 40px;
  position: relative;
  line-height: 40px;
  cursor: pointer;
}

.notiFi {
  display: flex;
  justify-content: space-between;
  border: 1px solid #d6edf4;
  border-radius: 10px;
  padding: 1.5rem 1rem;

  h6{
    font-weight: 600;
    color: #000;
    font-size: 16px;
  }
  .toggle-button-cover {
    position: relative;
  }
  .button-cover {
    background-color: #fff;
    box-shadow: 0 10px 20px -8px #c5d6d6;
    border-radius: 4px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .knobs {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
  .layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #ebf7fc;
    transition: 0.3s ease all;
    z-index: 1;
  }
  .button {
    position: relative;
    top: 50%;
    width: 77px;
    height: 28px;
    margin: -13px auto 0px -80px;
    overflow: hidden;
  }
  .button.r {
    border-radius: 30px;
    border: 1px solid #d6edf4;
    .layer {
      border-radius: 30px;
    }
  }
  .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }
  #button-1 {
    .knobs {
      &:before {
        content: "Later";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 44px;
        height: 26px;
        color: #fff;
        font-size: 10px;
        font-weight: bold;
        text-align: center;
        line-height: 1;
        line-height: 26px;
        background: #a8deef;
        border-radius: 30px;
        transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
        transition: 0.3s ease all;
      }
      transition: 0.3s ease all;
    }
    .checkbox {
      &:checked {
        + {
          .knobs {
            &:before {
              content: "Now";
              left: 34px;
              background: #53acb4;
            }
          }
        }
        ~ {
          .layer {
            background-color: #e7e7e7;
          }
        }
      }
    }
    .layer {
      transition: 0.3s ease all;
    }
  }
}