// .payment {
//   padding: 50px 0;
//   background: #f5f5f5;
//   min-height: calc(100vh - 75px);
//   display: flex;
//   align-items: center;

//     .rateBox {
//       background: #fff;
//       padding: 25px 35px;
//       border-radius: 15px;
//       position: relative;
//       .topBox {
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//         margin-bottom: 30px;
  
//         h4 {
//           font-weight: 600;
//           font-size: 20px;
//           margin-bottom: 0;
//         }
  
//         a {
//           font-size: 18px;
//           font-weight: 600;
//           color: #484848;
//           display: flex;
//           align-items: center;
  
//           span {
//             margin-right: 7px;
//           }
//         }
//       }
//       .flexBox {
//         border: 1px solid #d7d7d7;
//         padding: 10px 15px 30px;
//         border-radius: 10px;
//         margin: 10px 0;
//         .title{
//           display: flex;
//           justify-content: space-between;
//           align-items: center;
//           h6 {
//             color: #020202;
//             font-weight: 600;
//             font-size: 17px;
//             margin-bottom: 0px;
//           }
//           .docBtn{
//             .editBtn{
//               color: #808080;
//             }
//             .trashBtn{
//               color: #ff3d3d;
//               margin-left: 10px;
//             }
//           }
//         }
       
//         .mapText {
//           color: #808080;
//           font-weight: 500;
//           font-size: 15px;
//           display: flex;
//           align-items: center;
//           margin: 15px 0;
//           img {
//             width: 35px;
//             margin-right: 10px;
//           }
//         }
//         .date{
//           font-size: 15px;
//           color: #000;
//           font-weight: 500;
//           margin-bottom: 0;
//           span{
//             margin-right: 10px;
//           }
//         }
//       }
//     }
//   }
  
//   @media (max-width: 576px){
//     .payment{
//       .rateBox{
//         padding: 25px 15px;
//         .topBox{
//           margin-bottom: 15px;
//           a{
//             .add-span{
//               display: none;
//             }
//           }
//         }
//         .flexBox{
//           margin: 5px 0;
//         }
//       }
//     }
//   }
.mypayment {
  padding: 50px 0;
  background: #fff;
  min-height: calc(100vh - 60px);
  display: flex;
  // align-items: center;

  .myDocBox {
    border-radius: 20px;
    padding: 0px 15px;

    .topBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      h4 {
        font-weight: 600;
        font-size: 18px;
      }
    }

    .uploded_docs{
      margin-top: 25px;
      padding-top: 15px;
      border-top: 1px solid #d6edf4;
      h5{
        font-size: 17px;
        font-weight: 600;
      }
      .border-1{
        margin-top: 15px;
        border: 1px solid #d6edf4;
        border-radius: 8px;
        .table{
          text-align: left;
          margin: 0;
          thead{
            tr{
              border-width: 0;
            }
            th{
              font-size: 14px;
              font-weight: 700;
            }
          }
          tbody{
            border-top: 1px solid #d6edf4;
            tr{
              border: none;
            }
            td{
              font-size: 14px;
              font-weight: 500;
              border-right: 1px solid #d6edf4;
              border-left: none;
            }
          }
          a{
            vertical-align: -webkit-baseline-middle;
            color: #000;
          }
        }
        .attach_docs{
          display: flex;
          img{
            width: 50px;
            height: 35px;
            min-width: 50px;
            border: 1px solid #d6edf4;
            border-radius: 6px;
          }
          span{
            display: inline-block;
            width: 100%;
            margin-left: 5px;
            color: #66bbc0;
            font-size: 14px;
            font-weight: 600;
          }
        }
        .action_btn{
          display: flex;
          align-items: center;
          .del_btn{
            display: inline-block;
            font-size: 12px;
            color: #d71f41;
            i{
              color: #d71f41;
              margin-right: 5px;
            }
          }
          .edit_btn{
            display: inline-block;
            font-size: 12px;
            color: #3ea4a9;
            margin-right: 10px;
            i{
              color: #3ea4a9;
              margin-right: 5px;
            }
          }
        }
      }
    }

    .form-group {
      position: relative;
      margin: 8px 0;
      .form-control,.form-select {
        background-color: #f7f8fc;
        color: #000;
        border-radius: 10px;
        border-color: #c2d4da;
        padding-right: 35px;
        min-height: 40px;
        &:focus {
          border-color: #919191;
        }
        &::placeholder{
          color: #000;
        }
      }
      span {
        position: absolute;
        top: 40%;
        right: 15px;
        transform: translateY(-40%);
        color: #676767;
      }
    }

    .addDocs_btn{
      text-align: center;
      margin-top: 8px;
      a, button {
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        max-width: 185px;
        width: 100%;
        text-align: center;
        padding: 10px 15px;
        background: #000;
        border-radius: 10px;
      }
    }
  }
}


@media (max-width: 991px){
  .mypayment {
    .myDocBox {
      .form-group{
        .form-select{
          font-size: 13px;
        }
        .form-control{
          font-size: 13px;
        }
      }
      .uploded_docs{
        .border-1{
          .table{
            tbody{
              td{
                font-size: 13px;
              }
            }
          }
        }
      }
      .flexBox {
        .left {
          h5{
            font-size: 18px;
          }
          p{
            font-size: 15px;
          }
          button{
            font-size: 15px;
          }
        }
      }
    }
  }
}

@media (max-width: 576px){
  .mypayment {
    padding: 35px 0 25px;
    .myDocBox{
      padding: 0;
      .addDocs_btn{
        text-align: center;
        margin: 10px 0px;
      }
   
      .topBox{
       margin-bottom: 10px;
       justify-content: center;
      button{
        font-size: 14px;
      }
       h4{
        font-size: 20px;
      }
      a{
        .add-right{
          display: none;
        }
      }
    }
    .flexBox{
      padding: 15px 10px;
      align-items: initial;
      h5{
        text-align: center;
        margin-bottom: 0;
      }
        .imgBox{
          width: 100%;
          height: auto;
          margin: 15px 0;
          img{
            width: 100%;
            height: auto;
          }
        }
      .left{
        margin-top: 15px;
      }
    }
    }
  }
}

.payment_modal{
  .modal-dialog{
    max-width: 450px;
  }
  .modal-content{
    box-shadow: 0px 4px 7px 0px rgba(189, 219, 249, 0.36);
  }
  .modal-header{
    justify-content: center;
    border-bottom: none;
    padding-bottom: 0;
    .modal-title{
      font-size: 17px;
      font-weight: 600;
    }
  }

 .modal-body{
  .cardBox{
    .card_number{
    }
  }
  span{
    font-size: 14px;
    font-weight: 700;
  }
  p{
    font-weight: 500;
    font-size: 13px;
    margin-top: 3px;
  }
 }

  .pay_submit_box{
    text-align: center;
    margin: 0 0 1rem;
    .pay_submit_btn{
      display: inline-block;
      font-size: 14px;
      font-weight: 600;
      color: #fff;
      max-width: 135px;
      width: 100%;
      text-align: center;
      padding: 8px 0px;
      background: #000;
      border-radius: 10px;
    }
  }
  
}
