.all_awrded_job {
  padding: 25px 0;
  background: #fff;
  min-height: calc(100vh - 75px);

  .dateFormat {
    display: flex;
    justify-content: flex-end;
  }

  .form-group {
    position: relative;

    .form-control {
      background-color: #fff;
      padding-right: 0.75rem;
      font-size: 14px;
      min-height: 40px;
      font-weight: 500;

      &:focus {
        border-color: #9ad8f1;
      }
    }
  }

  .left_sidebar {
    border: 1px solid #9ad8f1;
    border-bottom: none;
    border-radius: 10px;
    text-align: left;

    h5 {
      font-size: 17px;
      color: #000;
      font-weight: 600;
      border-bottom: 1px solid #9ad8f1;
      padding: 8px 10px;
    }

    .nav_box {
      .nav-link {
        text-align: left;
        padding: 5px 10px;
        font-weight: 500;
        width: 100%;
        border-radius: 0;
        color: #000;
        font-size: 14px;
        border-bottom: 1px solid #9ad8f1;

        span {
          display: block;
          color: #727272;
          font-size: 13px;
          display: flex;
          align-items: center;

          i {
            margin-right: 5px;
          }
        }

        &.active {
          background: #f7f8fc;
          color: #000;
        }
      }
    }
  }

  .content {
    padding: 5px 15px;

    .awrded_job_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      color: #000;
      font-weight: 600;

      span {
        font-size: 13px;
        font-weight: 500;
      }
    }

    p {
      margin: 8px 0;
    }

    .adderess {
      font-size: 14px;
      color: #565656;
      font-weight: 500;
      margin-bottom: 0;
    }

    .desc {
      display: flex;

      .flexBox {
        width: 100%;

        p {
          font-size: 14px;
          color: #161616;
          font-weight: 500;

          span {
            display: inline-block;
            font-weight: 600;
            color: #000;
          }
        }

        &.spaceRight {
          margin-left: 70px;
        }
      }
    }

    .mapBox {
      margin-top: 15px;
    }

    .total_count_box {
      // margin-top: 15px;
      border: 1px solid #9ad8f1;
      padding: 15px 20px;
      border-radius: 10px;
      margin-bottom: 10px;

      .count_inner_box {
        display: flex;

        .lBox,
        .rBox {
          flex: 1;

          .box {
            margin: 10px 0;

            h6 {
              color: #000;
              font-size: 14px;
              font-weight: 600;
              margin-bottom: 0px;
            }

            span {
              display: inline-block;
              font-size: 13px;
              color: #565656;
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  .enote_shift_rBox {
    border: 1px solid #9ad8f1;
    padding: 15px 20px;
    border-radius: 10px;
    margin-bottom: 10px;

    .reach_box {
      border: 1px solid #9ad8f1;
      padding: 15px 20px;
      border-radius: 10px;
      margin-bottom: 10px;

      h5 {
        font-size: 17px;
        color: #000;
        font-weight: 600;
        margin-bottom: 3px;
      }

      h6 {
        color: #000;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0px;
      }

      p {
        color: #727272;
        font-size: 13px;
        margin-bottom: 0;
      }

      a {
        display: inline-block;
        font-size: 14px;
        color: #4097a0;
      }

      .shift_title {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        h6 {
          color: #000;
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 0px;
        }

        span {
          display: inline-block;
          font-size: 13px;
          color: #565656;
          font-weight: 500;
        }
      }

      .box {
        display: flex;
        align-items: center;
        border: 1px solid #9ad8f1;
        border-radius: 35px;
        overflow: hidden;
        padding: 0 10px;
        margin-top: 10px;
        height: 40px;

        .col-form-label {
          padding: 0;
          font-size: 14px;
        }

        .form-group {
          position: relative;
          margin-bottom: 0;
          flex: 1;

          .form-select {
            border: none;
            color: #383838;
            font-weight: 500;
            margin-bottom: 0;
            font-size: 14px;
            padding-top: 0;
            padding-bottom: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .all_awrded_job {
    .content {
      padding: 5px 0;

      .desc {
        .flexBox {
          p {
            font-size: 13px;
          }
        }
      }
    }

    .title {
      padding: 15px;
    }

    .left_sidebar {
      .nav_box {
        .nav-link {
          span {
            align-items: baseline;
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .all_awrded_job {
    .enote_shift_rBox {
      padding: 5px;
      
      .reach_box {
        padding: 10px 5px;

        .box {
          .col-form-label {
            font-size: 13px;
          }
        }
      }
    }

    .content {
      .total_count_box {
        padding: 12px 10px;

        .count_inner_box {

          .lBox,
          .rBox {
            .box {
              h6 {
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .all_awrded_job {
    .left_sidebar {
      .nav_box {
        .nav-link {
          span {
            i {
              margin-right: 8px;
            }
          }
        }
      }
    }

    .content {
      padding: 15px 0px;
      margin: 10px 0;
    }

    .dateBox {
      padding: 20px 15px;
    }

    .title {
      h4 {
        font-size: 18px;
      }

      .mike {
        p {
          font-size: 13px;
        }
      }
    }

    .shiftBox {
      padding: 15px 8px;
    }

    .dateFormat {
      justify-content: center;
    }
  }
}

.center {
  text-align: center;
}

.nodata_img {
  text-align: center;
  margin-top: 85px;

  img {
    width: 250px;
  }
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  position: absolute;
  bottom: -1.6em;
  left: 100%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background-image: -moz-linear-gradient(top, #f8f8f8, #cccccc);
  background-image: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(0, #f8f8f8),
      color-stop(1, #cccccc));
  background-image: -webkit-linear-gradient(top, #f8f8f8, #cccccc);
  background-image: -moz-linear-gradient(top, #f8f8f8, #cccccc);
  background-image: -ms-linear-gradient(top, #f8f8f8, #cccccc);
  background-image: -o-linear-gradient(top, #f8f8f8, #cccccc);
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}

[data-title] {
  position: relative;
}

.imgBox {
  min-width: 30px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 5px 0 0;

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.guard-imgBox {
  min-width: 100px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 5px 0 0;

  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.fade.guard_profile_modal {
  .modal-dialog {
    // max-width: 535px;
    max-width: 660px;

    .modal-content {
      border-radius: 15px;

      .modal-header {
        border-bottom: none;
        padding-bottom: 0;

        .modal-title {
          font-size: 18px;
          color: #000;
          font-weight: 600;
        }

        .btn-close {
          position: absolute;
          top: 10px;
          right: 15px;

          &:focus,
          &:focus-visible {
            box-shadow: none;
          }
        }
      }

      .modal-body {
        .cardBox {
          .form-group {
            .form-control {
              padding-right: 14px;
            }

            .licenceImg {
              width: 80px;
              margin-left: 15px;
            }
          }
        }
      }
    }
  }
}