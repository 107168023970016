.logoIcon{
    .imgBox{
        text-align: center;
        img{
            width: 250px;
        }
    }
}

@media (max-width: 576px){
    .logoIcon{
        .imgBox{
            text-align: center;
            img{
                width: auto;
            }
        }
    }
}