.add_Notification {
  padding-top: 15px;
  min-height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  background: #f5f5f5;
  // align-items: center;
  .loginForm {
    text-align: center;
    margin: 25px 0;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 35px;
    h4 {
      color: #000000;
      font-weight: 600;
      font-size: 20px;
      color: #000;
      margin-bottom: 35px;
    }
    .form-group {
      position: relative;
      margin: 15px 0;
      background-color: #fff !important;
      .form-control {
        border-radius: 25px;
        // border-color: #919191;
        padding-right: 35px;
        min-height: 50px;
        box-shadow: 0 0 5px rgba(205,205,205,0.7);
        width: 100%;
        max-width: 100%;
        &:focus {
          // border-color: #919191;
        }
        &::placeholder{
            color: #323232;
            font-weight: 500;
        }

        &.text_area{
          border-radius: 8px;
        }
      }
      span {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        color: #676767;
      }
    }
    
    .notReg {
      p {
        text-align: center;
        color: #6f8b99;
        font-weight: 500;
        font-size: 14px;
        .signupBtn {
          font-size: 16px;
          display: inline-block;
          font-weight: 700;
          color: #000000;
        }
      }
    }

    .nextBtn{
      background: #000;
      color: #fff;
      padding: 8px 5px;
      border-radius: 10px;
      font-weight: 400;
      font-size: 14px;
      margin-top: 20px;
      max-width: 165px;
      width: 100%;
      display: inline-block;
      text-align: center;
    }
  }
}

@media (max-width: 991px){
  .add_Notification{
    .loginForm{
      padding: 20px;
      .notReg{
        p{
          font-size: 13px;
        }
      }
    }
  }
}

@media (max-width: 991px){
  .add_Notification{
    .loginForm{
      padding: 20px 10px;
      margin: 25px 0 0;
      h4{
        margin-bottom: 15px;
      }
      .notReg{
        p{
          font-size: 13px;
        }
      }
      .form-group{
        margin: 5px 0;
      }
    }
  }
}
