footer{
    padding-bottom: 25px;
    text-align: center;
    color: #fff;
    position: relative;
    p{
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        font-size: 15px;
        color: #a7a8aa;
        
        span{
            margin-right: 5px;
            display: inline-block;
        }
        
    }
    &::before{
        content: ' ';
        position: absolute;
        height: 1px;
        background: #566167;
        left: 0;
        top: 10px;
        width: 32%;
    }
    &::after{
        content: ' ';
        position: absolute;
        height: 1px;
        background: #566167;
        right: 0;
        top: 10px;
        width: 32%;
    }
}

@media (max-width: 576px){
    footer{
        padding: 20px 0;
        &::before{
            top: 30px;
            width: 15%;
        }
        &::after{
            top: 30px;
            width: 15%;
        }
      p{
          font-size: 14px;
      }  
    }
}