.city_mall {
    padding: 25px 0 25px;
    background: #fff;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  
    .content {
      border-radius: 15px;
      background-color: #fff;
      border: 1px solid #d6edf4;

      .left_sidebar{
        border-right: 1px solid #d6edf4;
        text-align: left;
        height: calc(100vh - 200px);
        overflow: auto;
        .nav_box{
          .nav{
            .nav-link{
                text-align: left;
                padding: 5px 10px;
                font-weight: 500;
                width: 100%;
                border-radius: 0;
                color: #000;
                font-size: 14px;
                border-bottom: 1px solid #d6edf4;
                span{
                  display: block;
                  color: #adc1bd;
                  font-size: 13px;
                  display: flex;
                  align-items: center;
                }
                &.active{
                  background: #f7f8fc;
                  color: #000;
                }
              }
          }
        }
      }

      .innerBox{
          border-bottom: 1px solid #d6edf4;
          h5{
            font-size: 16px;
            color: #000;
            font-weight: 600;
            border-right: 1px solid #d6edf4;
            padding: 15px;
          }
        .title {
            display: flex;
            align-items: center;
            position: relative;
            padding: 15px;
              a {
                border: 0;
                outline: none;
                background: none;
                color: #000;
              }
            
            .heading {
              width: 100%;
              text-align: left;
              h1 {
                font-size: 16px;
                font-weight: 600;
              }
            }
            .form-group{
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 125px;
                margin: 0;
                .form-control{
                    padding: 0;
                    padding-right: 20px;
                    min-height: 0;
                    border: 0;
                }
                a{
                    color: #020202;
                    display: inline-block;
                    position: absolute;
                    right: 30px;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 14px;
                }
            }
          }
      }

      .historyBox{
          padding: 25px 50px;
          height: calc(100vh - 257px);
          overflow: auto;
          h6{
              font-size: 14px;
              font-weight: 600;
          }
          p{
              font-size: 13px;
              margin: 5px 0;
          }
          .MessageBox{
              .senderBox{
                  max-width: 500px;
                  border: 1px solid #01b399;
                  border-radius: 0px 8px 8px;
                  padding: 10px;
                  display: inline-block;
                  position: relative;
                  margin: 10px 0;
                  h6{
                      color: #020202;
                  }
                  .time{
                      text-align: right;
                      span{
                          display: inline-block;
                          font-size: 13px;
                          font-weight: 500;
                          color: #979696;
                      }
                  }
                  img{
                      position: absolute;
                      top: -1px;
                      left: -20px;
                      width: 20px;
                  }
              }
              .receiverBox{
                max-width: 500px;
                border: 1px solid #01b399;
                border-radius: 8px 0 8px 8px;
                background: #01b399;
                color: #fff;
                padding: 10px;
                position: relative;
                margin: 10px 0;
                float: right;

                &:after{
                    content: "";
                    position: absolute;
                    top: -1px;
                    right: -20px !important;
                    border-left: 20px solid #01b399;
                    border-bottom: 20px solid transparent;
                }
                .time{
                    text-align: right;
                    span{
                        display: inline-block;
                        font-size: 13px;
                        font-weight: 500;
                    }
                }
              }
          }
      }
      .textMessage{
        margin: 25px 10px 10px 0;
        .form-group{
            position: relative;
            .form-control{
                border-radius: 8px;
            }
            a{
                display: inline-block;
                position: absolute;
                right: 10px;
                top: 13px;
                text-align: center;
                line-height: 0;
                svg{
                    fill: #01b399;
                    font-size: 12px;
                }
            }
        }
      }
    }

    ::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #f5f5f5;
      }

      ::-webkit-scrollbar {
        width: 8px;
        height: 4px;
        background-color: #f5f5f5;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #d6edf4;
      }
  }
  

  @media (max-width: 576px)
  {
    .city_mall {
        .content {
            .historyBox{
                padding: 25px 20px;
                .MessageBox {
                    .senderBox,.receiverBox{
                        padding: 8px;
                    }
                }
                .textMessage{
                    margin-top: 35px;
                }
            }
            .innerBox{
                .title{
                    padding: 12px;
                    .backArrow{
                        left: 5px;
                    }
                    .heading{
                        h1{
                            font-size: 17px;
                        }
                    }
                    .form-group{
                        width: 65px;
                        a{
                            right: 5px;
                        }
                    }
                }
                h5{
                    border-right: none;
                    border-bottom: 1px solid #d6edf4;
                }
            }
            .textMessage{
                margin: 25px 10px 10px;
            }
        }
    }
  }