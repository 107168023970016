.add_head_office {
  min-height: calc(100vh - 200px);
  display: flex;
  justify-content: center;
  align-items: center;

  .signupForm {
    text-align: center;
    margin: 25px 0;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 35px;
    .location_title {
      text-align: left;
      margin-bottom: 25px;
      position: relative;
      .backBtn {
        display: inline-block;
        color: #000000;
        font-weight: 900;
        position: absolute;
        left: 0px;
        top: 0px;
        span{
            font-size: 20px;
        }
      }
      h4 {
        color: #000000;
        font-weight: 700;
        font-size: 22px;
        color: #000;
        margin-bottom: 0;
      }
      .searchBtn{
          position: absolute;
          right: 0;
          top: 0;
          color: #000000;
          font-weight: 900;
          span{
              font-size: 20px;
          }
      }
    }
    .mapBox{
        margin-bottom: 15px;
    }
    .signUpBox {
      text-align: left;
      position: relative;
      margin-bottom: 25px;
      .backBtn {
        display: inline-block;
        color: #000000;
        font-weight: 900;
        position: absolute;
        left: 0px;
        top: 2px;
      }
      h4 {
        color: #000000;
        font-weight: 700;
        font-size: 22px;
        color: #000;
      }
    }
    .form-group {
      position: relative;
      .form-control {
        border-radius: 25px;
        border: none;
        padding-right: 35px;
        min-height: 50px;
        box-shadow: 0 0 10px rgba(205,205,205,0.7);
        font-weight: 500;
        margin-bottom: 15px;
        &:focus {
          border-color: transparent;
        }
        &::placeholder{
            font-weight: 500;
            color: #000;
        }
      }
      span {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        color: #676767;
      }
    }
    .nextBtn {
      text-align: center;
      margin-top: 20px;
      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(#a0a0a0, #323232);
        color: #fff;
        border-radius: 25px;
        padding: 10px 50px;
        max-width: 250px;
        // width: 100%;
        span {
          margin-left: 5px;
        }
      }
    }
    h5 {
      color: #000000;
      font-weight: 700;
      text-align: center;
      font-size: 18px;
      margin: 25px 0;
    }
  }
}

@media (max-width: 1199px) {
  .add_head_office {
    .signupForm {
      padding: 35px 20px;
      .nextBtn {
        button {
          margin: 35px 0 0;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .add_head_office {
    .signupForm {
      margin: 25px 0 0;
      padding: 25px 10px;
      .location_title{
        h4{
          font-size: 18px;
        }
        .backBtn{
          top: -2px;
          span{
            font-size: 18px;
          }
        }
        .searchBtn{
          top: -2px;
          span{
            font-size: 18px;
          }
        }
      }
      .nextBtn {
        a{
          max-width: 220px;
        }
        button {
          margin: 15px 0 0;
         
        }
      }
    }
  }
}
