.invoice {
    padding: 25px 0;
    background: #fff;
    min-height: calc(100vh - 60px);
    display: flex;
    justify-content: center;
    align-items: center;
  
    .content {
      .title {
        h1 {
          font-size: 18px;
          font-weight: 600;
        }
        .form-group {
            margin-bottom: 0;
          .form-control {
            border-color: #000;
            min-height: 30px;
            background: #fff;
            &:focus {
              border-color: #000;
            }
          }
          i{
              color: #000;
          }
        }
      }
      .historyBox {
        .border-1{
          margin-top: 15px;
          border: 1px solid #d6edf4;
          border-radius: 8px;
          .table{
            text-align: left;
            margin: 0;
            thead{
                tr{
                  border-width: 0;
                }
                th{
                  font-size: 15px;
                  font-weight: 700;
                }
              }
            tbody{
                border: 1px solid #d6edf4;
            tr{
                border: none;
            }
            td{
                font-size: 14px;
                font-weight: 500;
                border: none;
                &:nth-child(1n){
                    border-right: 1px solid #d6edf4;
                }
            }
            }
            a{
              vertical-align: -webkit-baseline-middle;
              color: #24bcc9;
              font-weight: 400;
              font-size: 12px;
              display: flex;
              align-items: center;
              span{
                  margin-right: 5px;
                  i{
                    color: #24bcc9;
                  }
              }
            }
          }
        }
      }
    }
  }
@media(max-width: 1199px){
   .invoice{
       .detailsBox{
           .flexBox{
            padding: 10px 13px 30px;
           }
       }
   }
   
}

@media(max-width: 576px){
    .invoice{
      .content{
        .title{
          h1{
            margin-bottom: 15px;
            text-align: center;
          }
          .form-group{
            max-width: 70%;
            margin: 10px auto 0;
          }
        }
      }
        .detailsBox{
            padding: 25px 10px;
            .rate_title{
                h4{
                    text-align: center;
                }
                .earningBtn{
                    justify-content: center;
                    margin-top: 15px;
                    flex-direction: column;
                    a{
                        width: 100%;
                        text-align: center;
                    }
                    .updomeBtn{
                        margin: 0;
                        margin: 15px 0;
                    }
                }
                .form-group{
                    margin: 0;
                }
            }
            .flexBox{
                margin: 5px 0;
            }
        }
    }
}

@import "rsuite/dist/rsuite.css";