.myUnit {
    padding: 100px 0 25px;
    background: #fff;
    min-height: 100vh;
  
    .myDocBox {
      border-radius: 20px;
      padding: 0px 15px;
  
      .topBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
  
        h4 {
          font-weight: 600;
          font-size: 18px;
        }
      }
  
      .uploded_docs{
        margin-top: 15px;
        padding-top: 15px;
        border-top: 1px solid #d6edf4;
        h5{
          font-size: 17px;
          font-weight: 600;
        }
        .border-1{
          margin-top: 15px;
          border: 1px solid #d6edf4;
          border-radius: 8px;
          .table{
            text-align: left;
            margin: 0 0 1px 0px;
            thead{
              tr{
                border-width: 0;
              }
              th{
                font-size: 15px;
                font-weight: 700;
              }
            }
            tbody{
              border: 1px solid #d6edf4;
              tr{
                  border: none;
              }
              td{
                font-size: 14px;
                font-weight: 500;
                border: none;
                &:nth-child(1n){
                    border-right: 1px solid #d6edf4;
                }
              }
            }
            a{
              vertical-align: -webkit-baseline-middle;
              color: #000;
            }
          }
          .action_btn{
            display: flex;
            align-items: center;
            .del_btn{
              display: inline-block;
              font-size: 12px;
              i{
                color: #ff0907;
              }
            }
            .edit_btn{
              display: inline-block;
              font-size: 12px;
              margin-right: 10px;
              i{
                color: #31a3ae;
              }
            }
          }
        }
      }
  
      .form-group {
        .form-control,.form-select {
          color: #000;
          &::placeholder{
              color: #000;
          }
        }
      }
  
      .addDocs_btn{
        text-align: right;
        margin-top: 8px;
        a {
          display: inline-block;
          font-size: 14px;
          font-weight: 400;
          color: #fff;
          max-width: 185px;
          width: 100%;
          text-align: center;
          padding: 10px 15px;
          background: #000;
          border-radius: 10px;
        }
      }
    }
  }
  
  
  @media (max-width: 991px){
    .myUnit {
      .myDocBox {
        .flexBox {
          .left {
            h5{
              font-size: 18px;
            }
            p{
              font-size: 15px;
            }
            button{
              font-size: 15px;
            }
          }
        }
      }
    }
  }
  
  @media (max-width: 576px){
    .myUnit {
      padding: 50px 0 0px;
      .myDocBox{
        padding: 25px 0px;
        .addDocs_btn{
          text-align: center;
          a{
            max-width: none;
          }
        }
        .uploded_docs{
          margin-top: 25px;
        .border-1{
          .table{
            tbody{
              td{
                font-size: 13px;
              }
            }
          }
        }
      }
        .topBox{
          justify-content: center;
        button{
          font-size: 14px;
        }
        a{
          font-size: 16px;
        }
      }
      .flexBox{
        padding: 15px 10px;
        align-items: initial;
        h5{
          text-align: center;
        }
          .imgBox{
            width: 100%;
            height: auto;
            margin: 15px 0;
            img{
              width: 100%;
              height: auto;
            }
          }
        .left{
          margin-top: 15px;
        }
      }
      }
    }
  }