.inner_header_section{
    box-shadow: 0px 3px 16.2px 1.8px rgba(88, 178, 209, 0.15);
    background: #fff;
    text-align: center;
    padding: 10px 0;
    .inner_imgbox{
        img{
            width: 165px;
        }
    }
}