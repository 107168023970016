.privacy {
  padding: 50px 0;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  display: flex;
  min-height: calc(100vh - 75px);


  .content {
    border-radius: 15px;
    box-shadow: 0px 0px 8px #e5e5e5, 5px 5px 8px #e9e9e9;
    padding: 35px;
    background-color: #fff;
    .title {
      text-align: center;
      .heading {
        width: 100%;
        margin-bottom: 25px;
        h1 {
          font-size: 20px;
          font-weight: 700;
        }
      }
      p{
          font-size: 18px;
          font-weight: 500;
      }
    }
  }
}
@media (max-width: 991px){
  .privacy {
    .content{
      padding: 35px 25px;
      .title{
        p{
          font-size: 17px;
        }
      }
    }
  }
}

@media (max-width: 576px){
  .privacy {
    .content{
      padding: 35px 12px;
      .title{
        p{
          font-size: 16px;
        }
      }
    }
  }
}

