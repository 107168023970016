.add_job {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 35px 0;

  .signupForm {
    text-align: center;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 10px;

    .job_title {
      text-align: left;
      margin-bottom: 25px;
      position: relative;

      .backBtn {
        display: inline-block;
        color: #000000;
        font-weight: 900;
        position: absolute;
        left: 0px;
        top: 2px;

        span {
          font-size: 20px;
        }
      }

      h4 {
        color: #000000;
        font-weight: 600;
        font-size: 20px;
        color: #000;
        margin-bottom: 0;
      }
    }

    label {
      text-align: center;
      font-size: 14px;
      color: #000;
      font-weight: 600;
      margin-bottom: 0px;
      display: block;
    }

    .form-group {
      position: relative;
      // margin-bottom: 15px;

      select {
        margin-bottom: 15px;
      }

      .form-control {
        border-radius: 10px;
        padding-right: 35px;
        min-height: 45px;
        font-weight: 400;
        border-color: #d6edf4;
        font-size: 15px;
        background: #f7f8fc;

        &:focus {
          border-color: #d6edf4;
        }

        &::placeholder {
          color: #a4adbe;
        }

        &.startTime {
          padding-right: 10px;
        }
      }

      span {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        color: #676767;
        z-index: -1;
      }
    }

    .nextBtn {
      text-align: center;
      margin-top: 20px;

      a {
        display: block;
        align-items: center;
        justify-content: center;
        background: #000;
        color: #fff;
        border-radius: 10px;
        padding: 10px 8px;
        width: auto;

        span {
          margin-left: 5px;
        }
      }
    }

    .nextBtnNew {
      text-align: center;
      margin-top: 20px;

      a {
        align-items: center;
        justify-content: center;
        background: #000;
        color: #fff !important;
        border-radius: 10px;
        padding: 10px 8px;
        width: auto;

        span {
          margin-left: 5px;
        }
      }
    }

    .guard_title {
      margin: 15px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #d5d5d5;
      padding-top: 15px;

      h5 {
        text-align: left;
        font-size: 16px;
        font-weight: 600;
        flex: 1;
        margin-bottom: 0;
      }

      a {
        display: inline-block;
        flex: 1;
        text-align: right;
        color: #676767;
        font-weight: 600;

        span {
          color: #000;
          margin-right: 5px;

          i {
            color: #000;
          }
        }
      }

      .same_for_all {
        display: flex;
        align-items: center;

        p {
          font-size: 14px;
          font-weight: 500;
          color: #212529;
          margin-left: 5px;
        }
      }
    }

    .title_inner {
      text-align: left;
      font-size: 14px;
      font-weight: 600;
    }

    .title_inner_right {
      text-align: right;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
    }

    .Job_locate {
      text-align: center;

      a {
        border-radius: 10px;
        min-height: 45px;
        font-weight: 400;
        border: 1px solid #d6edf4;
        font-size: 15px;
        background: #f7f8fc;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0.375rem 0.75rem;
      }
    }

    .form-check-new {
      display: flex;
      align-items: center;
      padding-left: 0;

      // margin: 4px 0;
      .form-check-new-input {
        border-radius: 2px;
        border: 2px solid #3e3e3e;
        margin-top: 0;

        &:focus {
          box-shadow: none;
        }

        &:checked {
          background-color: #3e3e3e;
          box-shadow: none;
        }
      }

      .form-check-new-label {
        position: relative;
        font-weight: 500;
        color: #000000;
        font-size: 13px;
      }

      a {
        display: inline-block;
        color: #000000;
        font-size: 12px;
        margin-left: 5px;
      }

      .form-check-new-input {
        margin-left: 0;
        margin-right: 5px;
      }
    }

    .radioBtn {
      .form-check-new {
        margin: 5px 0;

        .form-check-new-input {
          border-radius: 50%;
        }
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      li {
        list-style: none;
      }
    }

    .form-check-new {
      display: flex;
    }

    .no_of_guards_box {
      position: relative;
      padding-right: 15px;

      .handle_tool_box {
        position: absolute;
        right: 0;
        top: 8px;
        width: 100%;

        .handle_tooltip_span {
          position: absolute;
          width: auto;
          right: -5px;
        }

        .css-yem0l2-MuiPopper-root-MuiTooltip-popper {
          // transform: none !important;

          .css-ja5taz-MuiTooltip-tooltip {
            max-width: 235px;
          }
        }
      }
    }

    .border-1 {
      margin-top: 15px;
      border: 1px solid #d6edf4;
      border-radius: 8px;

      .table {
        text-align: left;
        margin: 0;

        thead {
          tr {
            border-width: 0;
          }

          th {
            font-size: 14px;
            font-weight: 700;
          }
        }

        tbody {
          border-top: 1px solid #d6edf4;

          tr {
            border: none;

            td {
              font-size: 14px;
              font-weight: 500;
              border: 1px solid #d6edf4;
              border-left: none;

              &:last-child {
                border-right: none;
              }
            }

            &:last-child {
              td {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .add_job {
    .signupForm {
      .nextBtn {
        button {
          margin: 35px 0 0;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .add_job {
    .signupForm {
      .form-group {
        .form-control {
          font-size: 13px;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .add_job {
    .signupForm {
      label {
        text-align: left;
      }

      .form-group {
        .form-control {
          font-size: 15px;
        }
      }

      .Job_locate {
        margin-bottom: 15px;
      }

      .guard_title {
        a {
          .span-add {
            display: none;
          }

          &::before {
            width: calc(100% - 30px);
          }
        }

        h5 {
          font-size: 13px;
        }
      }

      .job_title {
        .backBtn {
          top: 0px;
        }
      }

      .nextBtn {
        a {
          width: 80%;
          margin: auto;
        }

        button {
          margin: 15px 0 0;
        }
      }

      ul {
        justify-content: space-between;
      }

      .no_of_guards_box {
        .handle_tool_box {
          .css-yem0l2-MuiPopper-root-MuiTooltip-popper {
            transform: translate3d(80px, 0px, 0px) !important;
          }
        }
      }
    }
  }

  .title_inner_right {
    text-align: center !important;
  }
}

@media (max-width: 375px) {
  .add_job {
    .signupForm {
      .no_of_guards_box {
        .handle_tool_box {
          .css-yem0l2-MuiPopper-root-MuiTooltip-popper {
            transform: none !important;
          }
        }
      }
    }
  }
}

// @media (max-width: 576px) {

// }

.custom-input {
  border-radius: 15px;
  border: 1px #0c8af8 solid;
  padding: 4px 12px;
  background-color: white;
  height: 22px;
  box-shadow: 0 0 2px #0074d9;
}

.custom-input:focus {
  outline: none;
  border: 1px solid #0c8af8;
  box-shadow: 0 0 10px 2px #0074d9;
}

.rmdp-day.rmdp-selected span.sd:not(.highlight) {
  width: 26px;
  height: 26px;
  padding: 0;
  color: #fff !important;
}

.form-group.custom_date_select {
  z-index: auto;
}

.custom_date_select .add_job .signupForm .form-group .sd {
  width: 26px;
  height: 26px;
}

.add_job .signupForm .form-group span.sd {
  right: 6px;
  height: 26px;
  width: 26px;
}

.rmdp-calendar {
  z-index: 0;
}

.rmdp-header>div {
  height: 100%;
}

span.rmdp-arrow-container {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}

span.rmdp-arrow-container i.rmdp-arrow {
  height: 8px;
  margin-top: 5px;
  padding: 2px;
  width: 8px;
  top: -3px;
  position: relative;
}

.rmdp-header .rmdp-header-values {
  min-width: 80px;
  min-height: 20px;
  display: block;
}

.rmdp-header .rmdp-header-values>span {
  position: relative !important;
  left: 0;
}

.rmdp-header span.rmdp-arrow-container.rmdp-right {
  margin-right: -8px;
  z-index: 99;
}

.rmdp-header span.rmdp-arrow-container.rmdp-left {
  z-index: 99;
}

.rmdp-year-picker .rmdp-ym .rmdp-day>span {
  height: 100%;
}

.rmdp-year-picker .rmdp-day.rmdp-today.rmdp-selected>span {
  color: #fff;
}

.rmdp-month-picker .rmdp-ym .rmdp-day>span {
  height: 100%;
}

.rmdp-month-picker .rmdp-ym .rmdp-day.rmdp-today.rmdp-selected>span {
  color: #fff;
}

.form-group.custom_date_select .rmdp-container {
  width: 100%;
}

.form-group.custom_date_select .rmdp-input {
  border-radius: 10px;
  padding-right: 35px !important;
  min-height: 45px;
  font-weight: 400;
  border-color: #d6edf4;
  font-size: 15px;
  background: #f7f8fc;
  width: 100%;
}


// @media( min-width: 577px ) {
//   .nextBtnNe{
//     width: 80%;
//     margin: auto;  }
//   }
.disFlex {
  display: flex;
}

// .infoSpan{
//   position: absolute;
//   top: 50%;
//   right: -35px;
//   line-height: 14px;
//   z-index: 99;
// }

.infoSpan .MuiTooltip-popper {
  min-width: 150px !important;
}

.note {
  padding: 25px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background: #fafafa;
  margin: 15px 0;
  text-align: left;
}