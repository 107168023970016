.thank_you {
  padding: 0;
  background: #fff;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .line_img{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;
    width: 96%;
  }
  .box{
    text-align: center;
    
    .midBox{
      border: 1px solid #000;
      position: relative;
      margin: 0px 15px;
      border-radius: 8px;
      padding: 10px 8px;
      background: #fff;
      h4{
        color: #000000;
        font-size: 16px;
        margin-bottom: 0;
      }
      img{
        position: absolute;
        width: 50px;
        left: 50%;
        transform: translateX(-50%);
        top: 100%;
      }
      .upperBox{
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        background: #4bb1b4;
        text-align: center;
        position: absolute;
        top: -200px;
        color: #fff;
        padding: 15px 10px;
        min-height: 150px;
        p{
          font-size: 14px;
          font-weight: 200;
          margin-top: 10px;
        }
        .ArroB{
          position: absolute;
          top: 97%;
          left: 50%;
          transform: translateX(-50%);
          width: 50px;
        }
      }
    }
  }
  
  .box1{
    text-align: center;
    display: flex;
    flex-direction: column;
    .midBox{
      border: 1px solid #000;
      position: relative;
      margin: 0px 15px;
      border-radius: 8px;
      padding: 10px 8px;
      background: #fff;
      h4{
        color: #000000;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0;
      }
      .upperBox{
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        background: #4bb1b4;
        text-align: center;
        color: #fff;
        padding: 15px 10px;
        min-height: 150px;
        order: 2;
        bottom: -195px;
        p{
          font-size: 14px;
          font-weight: 200;
          margin-top: 10px;
        }
        .ArroT{
          position: absolute;
          top: -25px;
          left: 50%;
          transform: translateX(-50%);
          width: 50px;
        }
      }
      .icon{
        width: 50px;
        top: -115px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    }
  }

  .content {
    padding: 25px;
    background-color: #fff;
    margin-top: 25px;
    text-align: center;
    // max-width: 1200px;
    margin: 0 auto;
    border-radius: 20px;
  }
}
.than_you_mob{
  display: none;
}
.top_sec{
  padding: 100px 0 0;
  text-align: center;
  background-color: #fff;
  h1{
    font-size: 32px;
    color: #242424;
    font-weight: 500;
  }
  p{
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 16px;
    color: #242424;
  }
}

.sec_bottom{
  background: #e9e9e9;
  padding: 25px 0;
  text-align: center;
  .secBox{
    display: flex;
    justify-content: center;
    align-items: center;
    span{
      color: #242424;
      font-size: 15px;
    }
    a{
      color: #4bb1b4;
      font-size: 14px;
      display: inline-block;
      line-height: 0;
      margin-left: 10px;
    }
  }
  .backToHome {
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      color: #4bb1b4;
      font-size: 14px;
      display: inline-block;
      line-height: 0;
      margin: 20px 0 0 0;
    }
  }
}
@media (min-width: 1680px) {
  .thank_you {
    min-height: 80vh;
  }
}

@media (max-width: 991px) {
  .thank_you{
    .box{
      .midBox{
        .upperBox{
          top: -200px;
          padding: 15px 5px;
        }
        h4{
          span{
            display: block;
          }
        }
      }
    }
    .box1{
      .midBox{
        .upperBox{
          bottom: -200px;
          padding: 15px 5px;
        }
        h4{
          span{
            display: block;
          }
        }
      }
    }
  }
}

@media (max-width: 850px) {
  .thank_you{
    .box{
      .midBox{
        .upperBox{
          top: -220px;
          padding: 15px 5px;
          min-height: 160px;
        }
        h4{
          span{
            display: block;
          }
        }
      }
    }
    .box1{
      .midBox{
        .upperBox{
          bottom: -220px;
          padding: 15px 5px;
          min-height: 180px;
        }
        h4{
          span{
            display: block;
          }
        }
      }
    }
  }
}
@media (max-width:576px) {
  .top_sec{
    h1{
      font-size: 24px;
      margin-bottom: 8px;
    }
  }
  .than_you_mob{
    padding: 0;
    background: #fff;
    min-height: 64vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .box_mob{
      text-align: center;
      margin: 30px 0px 0;
      h4{
        width: 50%;
        margin: auto;
        padding: 10px 0px;
        border-radius: 5px;
        border: 2px solid #000;
        font-size: 16px;
        font-weight: 600;
        color: #000000;
      }
      .apply_job{
        margin-bottom: 35px;
      }
      img{
        width: 50px;
        height: auto;
      }
        .upperBox_mob{
          margin: 15px 0px;
          background: #4bb1b4;
          text-align: center;
          margin: 15px auto 23px;
          position: relative;
          color: #fff;
          padding: 15px 10px;
          min-height: 150px;
          p{
            font-size: 14px;
            font-weight: 200;
            margin-top: 10px;
          }
          .ArroB{
            position: absolute;
            top: 97%;
            left: 50%;
            transform: translateX(-50%);
            width: 30px;
          }
        }
      }
    }
  
  .thank_you{
    display: none;
  }
  .sec_bottom{
    padding: 15px 0;
    .secBox{
      flex-direction: column;
      span{
        margin-bottom: 8px;
      }
    }
  }
  // .thank_you{
  //   .box{
  //     margin: 150px 0px;
  //     .midBox{
  //       width: 50%;
  //       margin: auto;
  //       h4{
  //         span{
  //           display: inline-block;
  //         }
  //       }
  //       .upperBox{
  //         top: -145px;
  //         min-height: 88px;
  //         width: 100%;
  //         left: 50%;
  //         .ArroB{
  //           width: 35px;
  //         }
  //         p{
  //           font-size: 14px;
  //         }
  //       }
  //     }
  //   }
  //   .box1{
  //     margin: 150px 0px;
  //     .midBox{
  //       width: 50%;
  //       margin: auto;
  //       .upperBox{
  //         min-height: 88px;
  //       }
  //       h4{
  //         span{
  //           display: inline-block;
  //         }
  //       }
  //       .icon{
  //         width: 50px;
  //         top: -70px;
  //         height: 70px;
  //       }
  //     }
  //   }
  // }
}
