.myDoc {
    padding: 50px 0;
    background: #fff;
    min-height: calc(100vh - 62px);
    display: flex;
    // align-items: center;
  
    .myDocBox {
      border-radius: 20px;
      padding: 0px 15px;
  
      .topBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
  
        h4 {
          font-weight: 600;
          font-size: 18px;
        }
      }
  
      .uploded_docs{
        margin-top: 25px;
        padding-top: 15px;
        border-top: 1px solid #d6edf4;
        h5{
          font-size: 17px;
          font-weight: 600;
        }
        .border-1{
          margin-top: 15px;
          border: 1px solid #d6edf4;
          border-radius: 8px;
          .table{
            text-align: left;
            margin: 0;
            thead{
              tr{
                border-width: 0;
              }
              th{
                font-size: 14px;
                font-weight: 700;
              }
            }
            tbody{
              border-top: 1px solid #d6edf4;
              tr{
                border: none;
              }
              td{
                font-size: 14px;
                font-weight: 500;
                border-right: 1px solid #d6edf4;
                border-left: none;
              }
            }
            a{
              vertical-align: -webkit-baseline-middle;
              color: #000;
            }
          }
          .attach_docs{
            display: flex;
            img{
              width: 50px;
              height: 35px;
              min-width: 50px;
              border: 1px solid #d6edf4;
              border-radius: 6px;
            }
            span{
              display: inline-block;
              width: 100%;
              margin-left: 5px;
              color: #66bbc0;
              font-size: 14px;
              font-weight: 600;
            }
          }
          .action_btn{
            display: flex;
            align-items: center;
            .del_btn{
              display: inline-block;
              font-size: 12px;
              margin-right: 10px;
              i{
                color: #ff0907;
              }
            }
            .edit_btn{
              display: inline-block;
              font-size: 12pxi{
                color: #565656;
              }
            }
          }
        }
      }
  
      .form-group {
        position: relative;
        margin: 8px 0;
        .form-select {
          background-color: #f7f8fc;
          color: #000;
          border-radius: 10px;
          border-color: #c2d4da;
          padding-right: 35px;
          line-height: 30px;
        }
      }
  
      .addDocs_btn{
        text-align: right;
        margin-top: 8px;
        a {
          display: inline-block;
          font-size: 14px;
          font-weight: 600;
          color: #fff;
          max-width: 185px;
          width: 100%;
          text-align: center;
          padding: 10px 15px;
          background: #000;
          border-radius: 10px;
        }
        a:hover {
          color: #fff !important;
        }
      }
    }
  }
  
  
  @media (max-width: 991px){
    .myDoc {
      .myDocBox {
        .form-group{
          .form-select{
            font-size: 13px;
          }
          .form-control{
            font-size: 13px;
          }
        }
        .uploded_docs{
          .border-1{
            .table{
              tbody{
                td{
                  font-size: 13px;
                }
              }
            }
          }
        }
        .flexBox {
          .left {
            h5{
              font-size: 18px;
            }
            p{
              font-size: 15px;
            }
            button{
              font-size: 15px;
            }
          }
        }
      }
    }
  }
  
  @media (max-width: 576px){
    .myDoc {
      padding: 35px 0 25px;
      .myDocBox{
        padding: 0;
        .addDocs_btn{
          text-align: center;
          margin: 10px 0px;
        }
     
        .topBox{
         margin-bottom: 10px;
         justify-content: center;
        button{
          font-size: 14px;
        }
         h4{
          font-size: 20px;
        }
        a{
          .add-right{
            display: none;
          }
        }
      }
      .flexBox{
        padding: 15px 10px;
        align-items: initial;
        h5{
          text-align: center;
          margin-bottom: 0;
        }
          .imgBox{
            width: 100%;
            height: auto;
            margin: 15px 0;
            img{
              width: 100%;
              height: auto;
            }
          }
        .left{
          margin-top: 15px;
        }
      }
      }
    }
  }
  


.update_files{
    border: 1px solid;
    display: block;
    text-align: center;
    border-radius: 10px;
    background: #f7f8fc;
    border-color: #ddecf0;
    border-width: 2px;
    color: #292a2e;
    padding-right: 30px;
    min-height: 40px;
    position: relative;
    line-height: 40px;
    cursor: pointer;
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 15px;
}