.reset_pass {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  background-size: cover;
  min-height: 100%;

  .resetForm {
    text-align: center;
    margin: 50px 0 0;
    background: #fff;
    border: 1px solid transparent;
    border-radius: 10px;
    padding: 35px;
    .form-check-input {
      border: 1px solid #000000;
      &:checked {
        background-image: none;
        background: #000000;
      }
      &:focus {
        box-shadow: none;
      }
    }
    .form-check-label {
      font-weight: 600;
      color: #000;
    }
    .signUpBox {
      text-align: left;
      position: relative;
      margin-bottom: 35px;
      h4 {
        color: #000000;
        font-weight: 700;
        font-size: 20px;
        color: #000;
        margin-bottom: 0;
      }
    }
    .form-group {
      position: relative;
      margin-bottom: 15px;
      .form-control {
        border-radius: 10px;
        border-color: #d6edf4;
        padding-right: 35px;
        min-height: 40px;
        // box-shadow: 0 0 10px rgba(205,205,205,0.7);
        font-weight: 400;
        color: #000;
        font-size: 16px;
        &:focus {
          border-color: #d6edf4;
        }
        &::placeholder{
            font-weight: 400;
            color: #6c757d;
        }
      }
      span {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        color: #676767;
        i{
          font-size: 12px;
          color: #676767;
        }
      }
    }
    .nextBtn {
      text-align: center;
      margin-top: 20px;
      a {
        display: inline-block;
        margin-top: 15px;
        background: #000;
        color: #fff !important;
        font-size: 14px;
        border-radius: 10px;
        padding: 10px 15px;
        max-width: 235px;
        width: 100%;
        i{
          margin-left: 2px;
          font-size: 13px;
        }
      }
    }
    .validation{
      margin-bottom: 0;
      .validator{
        font-size: 14px;
        align-items: baseline;
      }
    }
  }
}

@media (max-width: 1199px) {
  .reset_pass {
    .resetForm {
      padding: 35px 15px;
      .nextBtn {
        button {
          margin: 35px 0 0;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .reset_pass {
    .resetForm {
      padding: 25px 10px;
      .nextBtn {
        button {
          margin: 15px 0 0;
        }
        a{
          width: 210px;
        }
      }
    }
  }
}

$color--error: #F2180A;
$color--success: #11AF41;

  .validation {
    // display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-sizing: border-box;
    margin-bottom: 1em;
  }
  
  .validation-item {
    margin-bottom: 0.85em
  }
  
  .validation-icon {
    display: inline-block;
    margin-right: 0.5em;
    width: 1.25em;
  }
  
  .validator {
    display: flex;
  }
  
  .error {
    @extend .validation-icon;
    color: $color--error;
  }
  
  .success {
    @extend .validation-icon;
    color: $color--success;
  }
