.edit_location {
  padding: 100px 0 25px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;

  .signupForm {
    text-align: center;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    .location_title {
      text-align: left;
      margin-bottom: 25px;
      position: relative;
      // .backBtn {
      //   display: inline-block;
      //   color: #000000;
      //   font-weight: 900;
      //   position: absolute;
      //   left: 0px;
      //   top: 2px;
      //   span{
      //       font-size: 20px;
      //   }
      // }
      h4 {
        color: #000000;
        font-weight: 600;
        font-size: 22px;
        color: #000;
        margin-bottom: 0;
      }
      .editDetail {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        background: #000;
        border-radius: 5px;
        a {
          display: inline-block;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-weight: 500;
          font-size: 11px;
          padding: 5px 8px;
          span{
            margin-left: 5px;
          }
          i{
            color: #fff;
          }
        }
      }
    }
    .mapBox{
        margin-bottom: 15px;
    }
    .signUpBox {
      text-align: left;
      position: relative;
      margin-bottom: 25px;
      .backBtn {
        display: inline-block;
        color: #000000;
        font-weight: 900;
        position: absolute;
        left: 0px;
        top: 2px;
      }
      h4 {
        color: #000000;
        font-weight: 700;
        font-size: 22px;
        color: #000;
      }
    }
    .nextBtn {
      text-align: center;
      margin-top: 20px;
      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #000;
        color: #fff;
        border-radius: 10px;
        padding: 10px 50px;
        max-width: 300px;
        font-size: 15px;
        width: 100%;
        span {
          margin-left: 5px;
        }
      }
    }
    h5 {
      color: #000000;
      font-weight: 600;
      text-align: center;
      font-size: 17px;
      margin: 20px 0;
      border-top: 1px solid #ccc;
      padding-top: 20px;
    }
  }
}

@media (max-width: 1199px) {
  .edit_location {
    .signupForm {
      padding: 35px 20px;
      .nextBtn {
        button {
          margin: 35px 0 0;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .edit_location {
    padding: 40px 0 0px;
    .signupForm {
      .location_title{
        margin-bottom: 20px;
        .editDetail{
          span{
            display: none;
          }
        }
      }
      margin: 20px 0 0;
      padding: 25px 0px;
      h5{
        padding-top: 15px;
        margin: 5px 0 15px;
      }
      .nextBtn {
        margin-top: 10px;
        a{
          max-width: none;
        }
        button {
          margin: 15px 0 0;
        }
      }
    }
  }
}
