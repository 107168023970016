@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body h1,h2,h3,h4,h5,h6,p,ul,li,a{
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 0;
    margin-top: 0;
}
body{
    background: url(assets/img/home1_bg.png) no-repeat center center;
    background-size: cover;
}
body p{
    margin-bottom: 0;
}
body a{
    text-decoration: none;
}
body ul{
    padding-left: 0;
}
body ul li{
    list-style: none;
}
body .fas, body .fa{
    /* background: -webkit-gradient(linear, left top, left bottom, from(#eee), to(#333));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; */
        color: #8194b0;
        font-size: 14px;
}
/* body .left i, body .right i{
    background: none;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: unset;
} */
body button{
    border: none;
    outline: none;
    background: transparent;
}
body button:focus{
    box-shadow: none;
}
body .form-control:focus,body .form-select:focus{
    box-shadow: none;
}
body .form-group{
    position: relative;
    margin-bottom: 5px;
    background: #f7f8fc !important;
    z-index: 1;
}
body .form-group span {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    color: #676767;
    line-height: 0;
}
body .form-group .form-control {
    border-radius: 10px;
    border-color: #d6edf4;
    padding-right: 35px;
    min-height: 45px;
    font-weight: 500;
    background: transparent !important;
    box-shadow: none;
    font-size: 14px;
}
body .form-group .form-control:focus {
    border-color: transparent;
}
body .form-group .form-control::placeholder{
      font-weight: 500;
      color: #849bb4;
}
body .nextBtn a:hover,body .loginBtn a:hover{
    color: #43a1a9 !important
}
body .nextBtn a:hover i,body .loginBtn a:hover i{
    color: #43a1a9 !important
}
body ::-webkit-file-upload-button {
    display: none;
}
.form-control[type=file] {
    overflow: hidden;
    line-height: 30px;
    color: #adb2be;
}

ul.pagination {
    display: flex;
    padding: 0;
    margin: 0;
    justify-content: center;
}
ul.pagination li a {
  position: relative;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  display: block;
  color: #0d6efd;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

ul.pagination li.active .undefined {
    background-color: #439fa8;
    color: white;
    border-radius: 0px;
    border-color: transparent;
}

ul.pagination li:first-child a{
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
ul.pagination li:last-child a{
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
ul.pagination li a:hover:not(.active) 
{
    background-color: #ddd;
    color: #0d6efd;
}


::-webkit-scrollbar {
    width: 5px;
    height: 3px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #449fa8; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #449fa8; 
    border-radius: 10px;
  }

input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
}
/* 
  input[type="date"]::before{
    color: #8194c1;
    content: attr(placeholder) "";
    font-weight: 500;
 }
 input[type="date"]:focus::before{
    display: none;
 } */

 /* input[type=date]::-webkit-datetime-edit-text {
    -webkit-appearance: none;
    display: none;
} */
/* input[type=date]::-webkit-datetime-edit-month-field{
    -webkit-appearance: none;
    display: none;
}
input[type=date]::-webkit-datetime-edit-day-field {
    -webkit-appearance: none;
    display: none;
}
input[type=date]::-webkit-datetime-edit-year-field {
    -webkit-appearance: none;
    display: none;
} */

@media (max-width: 576px){
    a,button{
        min-height: 24px !important;
    }
}