.loginPage {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 85px 0 25px;
  .loginForm {
    text-align: center;
    background: #fff;
    border: 1px solid #d6edf4;
    border-radius: 10px;
    padding: 35px;
    margin-top: 15px;
    h4 {
      color: #000000;
      font-weight: 600;
      font-size: 17px;
      color: #000;
    }
    .form-group {
      position: relative;
      margin: 15px 0 0;
      .form-control {
        border-radius: 10px;
        border-color: #d6edf4;
        padding-right: 35px;
        min-height: 40px;
        background: #f7f8fc;
        color: #849bb4;
        font-size: 15px;
        &:focus {
          border-color: #d6edf4;
        }
        &::placeholder{
          color: #849bb4;
        }
      }
      span {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        color: #000000;
        cursor: pointer;
      }
    }
    .loginBtn {
      text-align: center;
      a {
        display: inline-block;
        margin-top: 15px;
        background: #000;
        color: #fff !important;
        font-size: 14px;
        border-radius: 10px;
        padding: 10px 15px;
        max-width: 235px;
        width: 100%;
        i{
          color: #fff;
          margin-left: 5px;
        }
      }
    }
    .fbBtn {
      text-align: center;
      button {
        color: #fff;
        display: inline-block;
        max-width: 228px;
        width: 100%;
        background: #0178f2;
        border-radius: 10px;
        padding: 9px 15px;
        position: relative;
        margin: 25px auto 0px;
        border: 1px solid transparent;
        box-shadow: 0px 4px 7px 0px rgba(1, 120, 242, 0.36);
        i {
          position: absolute;
          left: 10px;
          top: 14px;
        }
      }
      .kep-login-facebook{
        font-size: 14px;
        font-weight: 400;
      }
    }
    .googleBtn {
      text-align: center;
      button {
        color: #323232;
        display: inline-block;
        max-width: 235px;
        width: 100%;
        background: transparent;
        font-size: 15px;
        border-radius: 10px;
        padding: 10px 15px;
        position: relative;
        margin: 12px auto 0px;
        box-shadow: 0px 4px 7px 0px rgba(213, 220, 222, 0.36);
        border: 1px solid #cacaca;
        span {
          position: absolute;
          left: 48px;
          padding: 10px 10px 10px 0px !important;
          display: inline-block;
          font-size: 14px;
          img {
            width: 18px;
          }
        }
      }
    }
    .notReg {
      margin-top: 35px;
      padding-top: 25px;
      border-top: 1px solid #ede5e5;
      p {
        text-align: center;
        color: #6f8b99;
        font-weight: 500;
        font-size: 14px;
        .signupBtn {
          font-size: 15px;
          display: inline-block;
          font-weight: 600;
          color: #000000;
        }
      }
    }
    .forgot-password{
      text-align: right;
      a{
        font-size: 14px;
        color: #000;
        font-weight: 500;
      }
    }
  }
  .login_with_pass{
    border-right: 1px solid #bfbfbf;
    padding-right: 25px;
  }
}

.kep-login-facebook {
  text-transform: capitalize !important;
}

@media (max-width:1199px) {
  .loginPage{
    .loginForm{
      .fbBtn{
        button{
          margin: 10px auto 0px;
        }
      }
    }
  }
}
@media (max-width: 991px){
  .loginPage{
    .loginForm{
      padding: 20px 20px;
      margin: 25px 0 0;
      h4{
        font-size: 16px;
      }
      .notReg{
        p{
          font-size: 13px;
        }
      }
    }
  }
}
@media (max-width:576px) {
  .loginPage{
    padding: 65px 0 25px;
    .loginForm{
      .fbBtn{
        button{
          max-width: none;
          margin: 20px auto 0px;
          i{
            position: static;
            margin-right: 5px;
          }
        }
      }
      .googleBtn{
        button{
          max-width: none;
          justify-content: center;
          span{
            position: static;
          }
        }
      }
      .form-group{
        .form-control{
          margin-top: 25px;
        }
      }
      .loginBtn{
        margin-bottom: 50px;
        a{
          max-width: none;
        }
      }
    }
    .login_with_pass{
      border-right: none;
      border-bottom: 1px solid #bfbfbf;
      margin-bottom: 20px;
      padding-right: 0%;
    }
  }
}


