.add_inner_location {
    padding: 100px 0 25px;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;

    .location_title {
        text-align: left;
        position: relative;
        margin-bottom: 25px;
        .backBtn {
          display: inline-block;
          color: #000000;
          font-weight: 900;
          position: absolute;
          left: 0px;
          top: 2px;
          span{
              line-height: 0;
          }
        }
        h4 {
          color: #000000;
          font-weight: 600;
          font-size: 20px;
          color: #000;
          margin-bottom: 0;
          text-align: left;
        }
      }
  
    .signupForm {
      text-align: center;
      background: #fff;
      border: 1px solid #fff;
      border-radius: 10px;
      .mapBox{
          margin-bottom: 15px;
      }
      .signUpBox {
        text-align: left;
        position: relative;
        margin-bottom: 25px;
        .backBtn {
          display: inline-block;
          color: #000000;
          font-weight: 900;
          position: absolute;
          left: 0px;
          top: 2px;
        }
      }
      h5 {
        color: #000000;
        font-weight: 600;
        text-align: left;
        font-size: 17px;
        margin: 20px 0 25px;
        border-top: 1px solid #ccc;
        padding-top: 20px;
      }
    }

    .nextBtn {
      text-align: center;
      margin-top: 20px;
      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #000;
        border-radius: 10px;
        padding: 10px 10px;
        max-width: 235px;
        font-size: 15px;
        width: 100%;
        // width: 100%;
        span {
          margin-left: 5px;
        }
      }
    }
  }
  
  @media (max-width: 1199px) {
    .add_inner_location {
      .signupForm {
        .nextBtn {
          button {
            margin: 35px 0 0;
          }
        }
      }
    }
  }
  
  @media (max-width: 576px) {
    .add_inner_location {
      padding: 85px 0 25px;
      .location_title{
        margin-bottom: 0;
        h4{
          text-align: center;
        }
      }
      .signupForm {
        margin: 0 0;
        padding: 20px 0px;
        h5{
          margin: 15px 0;
          padding-top: 15px;
        }
      }
      .nextBtn {
        a{
          max-width: none;
        }
        button {
          margin: 15px 0 0;
        }
      }
    }
  }
  