.MyProfie {
  padding: 25px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  min-height: 100vh;

  .profile {
    border-radius: 10px;
    background-color: #fff;
    padding: 20px;
    margin-bottom: 30px;
    border: 1px solid #9ad8f1;

    h4 {
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 20px;
    }
    .editProBtn {
      text-align: right;
      margin-bottom: 20px;
      a {
        display: inline-block;
        color: #4c4c4c;
        font-weight: 600;
        font-size: 12px;
        span {
          margin-right: 5px;
        }
      }
    }
    .flexBox {
      display: flex;

      .left {
        width: 55%;

        .box {
          display: flex;
          border-right: 1px solid #b7b7b7;

          .imgBox {
            min-width: 55px;
            width: 55px;
            height: 55px;
            border-radius: 50%;
            overflow: hidden;

            img {
              width: 55px;
              height: 55px;
              border-radius: 50%;
              object-fit: cover;
            }
          }
          .contentBox {
            padding-left: 25px;
            width: 100%;
            p {
              font-size: 16px;
              margin-bottom: 10px;
              font-weight: 500;
              display: flex;
              align-items: center;
              span{
                font-size: 16px;
                margin-right: 5px;
                line-height: 0;
              }
            }

            ul {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              padding-left: 0;

              li {
                font-size: 14px;
                display: flex;
                align-items: center;
                color: #666666;
                margin-right: 10px;
                font-weight: 500;

                span {
                  font-size: 14px;
                  margin-right: 5px;
                  line-height: 0;
                }
              }
            }

            .btnBox {
              margin-top: 30px;

              .docBtn,
              .accBtn {
                background: #000;
                color: #fff;
                padding: 8px 5px;
                border-radius: 10px;
                font-weight: 400;
                font-size: 14px;
                margin-right: 10px;
                max-width: 165px;
                width: 100%;
                display: inline-block;
                text-align: center;
              }
            }
          }
        }
      }
      .right {
        width: 45%;

        .flexInner {
          display: flex;
          padding: 0 30px;
          margin-bottom: 25px;

          .left,.right {
            p {
              font-size: 15px;
              margin: 0;
              font-weight: 600;
            }
            .coins {
              font-weight: 500;
              i {
                color: #ffd40c;
              }
            }
          }

          .right {
            p {
              font-size: 15px;
              margin: 0;
              font-weight: 600;

              i {
                font-size: 16px;
              }
            }
            a {
              display: block;
              color: #020202;
              font-size: 15px;
              font-weight: 600;

              svg {
                margin-right: 7px;
                font-size: 16px;
              }
            }
            span {
              font-size: 16px;
              font-weight: 500;

              &.count {
                font-size: 16px;
              }

              &.starIcon {
                i {
                  color: #ffd40c;
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }
  }

  .allJob,
  .myEarning {
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #9ad8f1;
    padding: 20px;
    height: 100%;

    .topBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;

      h4 {
        font-weight: 600;
        font-size: 17px;
        margin-bottom: 0;

        span {
          font-size: 18px;
        }
      }

      a {
        font-size: 13px;
        font-weight: 600;
        color: #484848;
        display: flex;
        align-items: center;
        border: none;
        outline: none;

        svg {
          margin-left: 7px;
          font-size: 16px;
        }
      }
    }
    .trans_box{
      border: 1px solid #929292;
      padding: 20px 30px;
      border-radius: 10px;
      margin-bottom: 15px;
      h5{
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 0;
      }
      p{
        margin: 5px 0 0;
        display: flex;
        align-items: center;
      }
      .leftPara{
        color: #737373;
        font-size: 15px;
        font-weight: 500;
        span{
          margin-right: 5px;
          i{
            color: #000;
          }
        }
      }
      .rightPara{
        font-weight: 600;
        font-size: 16px;
        text-align: right;
        display: block;
        color: #fe4b4b;
      }
    }

    .box {
      background: #def6f8;
      padding: 20px 30px;
      border-radius: 10px;
      position: relative;
      overflow: hidden;
      margin-bottom: 10px;
      z-index: 1;

      .job_title_box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        h5{
          margin-bottom: 0;
          color: #000;
          font-size: 16px;
          font-weight: 600;
        }
        span{
          line-height: 0;
          i{
            color: #000;
          }
        }
      }
      p {
        color: #97baba;
        font-size: 14px;
        margin: 0;
        position: relative;
        z-index: 1;
        margin-bottom: 5px;
        font-weight: 500;

        span {
          margin-right: 8px;
          font-weight: 400;
        }
      }
    }

    .nav-tabs {
      border: 0;
      justify-content: center;
      margin: 30px 0;

      .nav-item {
        margin: 0 5px;

        .nav-link {
          border: 1px solid #505050;
          padding: 7px 35px;
          border-radius: 25px;
          font-weight: 600;
          color: #000000;
          font-size: 16px;

          &.active {
            background: linear-gradient(#a0a0a0, #323232);
            color: #fff;
            border: 1px solid #fff;
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .MyProfie {
    .profile {
      padding: 20px 15px;
      .flexBox {
        .left {
          .box {
            .contentBox {
              padding-left: 20px;
              p {
                font-size: 18px;
              }
              ul {
                li {
                  font-size: 15px;
                  margin-right: 20px;
                }
              }
              .btnBox {
                .docBtn,
                .accBtn {
                  font-size: 13px;
                  max-width: 135px;
                  padding: 10px 15px;
                }
              }
            }
            .imgBox {
              width: 65px;
              height: 65px;
              img {
                width: 65px;
                height: 65px;
              }
            }
          }
        }
        .right {
          .flexInner {
            .left {
              p {
                font-size: 16px;
              }
              span {
                font-size: 13px;
              }
            }
            .right {
              p,a{
                font-size: 16px;
              }
              span {
                font-size: 13px;
                &.starIcon {
                  svg {
                    font-size: 13px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .allJob,
    .myEarning {
      padding: 20px;
      .trans_box{
        padding: 20px;
      }
      .topBox {
        h4 {
          font-size: 18px;
        }
      }
      .nav-tabs{
        .nav-item{
          margin-bottom: 10px;
          width: 100%;
          max-width: 275px;
          .nav-link{
            width: 100%;
          }
        }
      }
      .box{
        h5{
          font-size: 18px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .MyProfie {
    .profile{
      padding: 20px 13px;
    }
    .allJob{
      .box{
        p{
          font-size: 15px;
        }
      }
    }
    .profile {
      .flexBox {
        .left {
          .box {
            flex-direction: column;
            .imgBox {
              margin: auto;
            }
          }
        }
        .right {
          .flexInner {
            padding: 0 15px;
            .left,
            .right {
              p {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
    .allJob{
      padding: 15px;
      .box{
        padding: 10px 15px;
        .mapBox{
          iframe{
            height: 70px !important;
          }
        }
      }
    }
     .nav-tabs,
    .myEarning {
      padding: 15px;
      .nav-tabs {
        .nav-item {
          width: 100%;
        }
      }
      .trans_box{
        h5{
          font-size: 16px;
        }
      }
    }
  }
}
@media (max-width: 576px){
  .MyProfie{
    .allJob{
      height: auto;
      margin-bottom: 30px;
      .box{
        padding: 20px;
      }
    }
    .profile {
      h4{
        font-size: 18px;
      }
      .flexBox {
        flex-direction: column;
        .left{
          width: 100%;
          .box{
            border-right: none;
            border-bottom: 1px solid #b7b7b7;
            padding-bottom: 15px;
            .contentBox{
              padding-left: 0;
              margin-top: 15px;
              .btnBox{
                text-align: center;
                margin-top: 15px;
                .docBtn,.accBtn{
                  padding: 7px 10px;
                  margin-top: 5px;
                  font-size: 12px;
                  max-width: 113px;
                }
              }
            }
          }
        }
        .right{
          width: 100%;
          margin-top: 15px;
          .flexInner{
            padding: 0;
            margin-bottom: 15px;
            .right{
              margin-top: 0;
            }
          }
        }
      }
    }
    .allJob,.myEarning{
      .topBox{
        a{
          font-size: 16px;
        }
      }
      .box{
        padding: 10px;
        .mapBox{
          margin-top: 10px;
          iframe{
            height: auto !important;
          }
        }
      }
    }
    }
}

.starRate{
  display: flex;
  span{
    line-height: initial;
    svg{
      // fill: #facf1e;
      width: 18px;
      height: auto;
    }
  }
}

@media( min-width: 577px ) {
  .deskHide{
  display: none;
  }
  }
  
