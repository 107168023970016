.mall_job {
  padding: 50px 0;
  background: #f5f5f5;
  min-height: 100%;

  .dateBox{
    border-radius: 20px;
    background-color: #fff;
    padding:35px;
    box-shadow: 0 0 10px 1px rgba(133, 133, 133, 0.1);
    .content{
        text-align: center;
      h4{
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 8px;
      }
      p{
          margin-bottom: 8px;
          font-weight: 500;
          span{
              margin-right: 5px;
          }
      }
    }
  }
  
  .shiftBox{
    border: 1px solid #919191;
    border-radius: 10px;
    padding: 30px 20px;
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 200px;
    h5{
        font-weight: 600;
        font-size: 20px;
    }
  .descBox{
   a{
       display: flex;
       align-items: center;
       color: #020202;
       margin-bottom: 15px;
       span{
           margin-left: 25px;
           display: inline-block;
           border: 2px solid #d5d5d5;
           width: 75%;
           position: relative;
           &::before{
               content: '';
               position: absolute;
               border: 1px solid #4dcac4;
               width: 15px;
               height: 15px;
               left: -10px;
               top: -7px;
               background: #fff;
               border-radius: 50%;
           }
           &::after{
              content: "0:25";
              position: absolute;
              width: 15px;
              height: 15px;
              right: -25px;
              top: -10px;
              background: #fff;
              color: #d5d5d5;
              border-radius: 50%;
              font-size: 14px;
          }
       }
   }
   p{
       display: flex;
       align-items: center;
       span{
           margin-right: 3px;
           display: inline-block;
       }
   }
  }
  }
}

@media (max-width: 1199px) {
  .mall_job {
    .title {
      padding: 15px 10px;
    }
  }
}

@media (max-width: 576px){
  .mall_job{
    .shiftBox{
      padding: 15px 10px;
      min-height: initial;
      margin-top: 15px;
      .descBox{
        a{
          span{
            width: 65%;
          }
        }
      }
    }
    .dateBox{
      padding: 30px 15px;
    }
    .title {
      h4{
        font-size: 18px;
      }
    }
    }
}
  