.add_card {
    min-height: calc(100vh - 75px);
    display: flex;
    justify-content: center;
    background: #f5f5f5;
    align-items: center;
    .loginForm {
      text-align: center;
      margin: 25px 0;
      background: #fff;
      border: 1px solid #fff;
      border-radius: 10px;
      padding: 35px;
      h4 {
        color: #000000;
        font-weight: 600;
        font-size: 20px;
        color: #000;
        margin-bottom: 35px;
      }
      .form-group {
        position: relative;
        margin: 15px 0;
        .form-control {
          border-radius: 25px;
          border-color: #919191;
          padding-right: 35px;
          min-height: 50px;
          box-shadow: 0 0 10px rgba(205,205,205,0.7);
          &:focus {
            border-color: #919191;
          }
          &::placeholder{
              color: #323232;
              font-weight: 500;
          }
        }
        span {
          position: absolute;
          top: 50%;
          right: 15px;
          transform: translateY(-50%);
          color: #676767;
        }
      }
      .loginBtn {
        text-align: center;
        a {
          display: inline-block;
          margin-top: 15px;
          background: linear-gradient(#6c6c6c, #404040);
          color: #fff;
          font-size: 14px;
          border-radius: 25px;
          padding: 10px 15px;
          max-width: 235px;
          width: 100%;
        }
      }
      .fbBtn {
        text-align: center;
        button {
          color: #fff;
          display: inline-block;
          max-width: 235px;
          width: 100%;
          background: #0178f2;
          font-size: 15px;
          border-radius: 25px;
          padding: 10px 15px;
          position: relative;
          margin: 25px auto 0px;
          box-shadow: 0px 4px 7px 0px rgba(1, 120, 242, 0.36);
          border: 1px solid transparent;
          span {
            position: absolute;
            left: 10px;
          }
        }
      }
      .googleBtn {
        text-align: center;
        button {
          color: #323232;
          display: inline-block;
          max-width: 235px;
          width: 100%;
          background: transparent;
          font-size: 15px;
          border-radius: 25px;
          padding: 10px 15px;
          position: relative;
          margin: 25px auto 15px;
          box-shadow: 0px 4px 7px 0px rgba(213, 220, 222, 0.36);
          border: 1px solid #cacaca;
          span {
            position: absolute;
            left: 15px;
            img {
              width: 18px;
            }
          }
        }
      }
      .notReg {
        p {
          text-align: center;
          color: #6f8b99;
          font-weight: 500;
          font-size: 14px;
          .signupBtn {
            font-size: 16px;
            display: inline-block;
            font-weight: 700;
            color: #000000;
          }
        }
      }
    }
  }
  
  @media (max-width: 991px){
    .add_card{
      .loginForm{
        padding: 20px;
        .notReg{
          p{
            font-size: 13px;
          }
        }
      }
    }
  }
  
  @media (max-width: 991px){
    .add_card{
      .loginForm{
        padding: 20px 10px;
        margin: 25px 0 0;
        h4{
          margin-bottom: 15px;
        }
        .notReg{
          p{
            font-size: 13px;
          }
        }
        .form-group{
          margin: 5px 0;
        }
      }
    }
  }
  