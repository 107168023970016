.tiket_detail {
  padding: 25px 0;
  background: #fff;
  min-height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    border: 1px solid #d6edf4;
    border-radius: 15px;
    .contentBox{
      padding: 20px;
      .title {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        position: relative;
        .backArrow {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          a {
            border: 0;
            outline: none;
            background: none;
            color: #000;
          }
        }
        .heading {
          width: 100%;
          text-align: center;
          h1 {
            font-size: 18px;
            font-weight: 600;
          }
          h6 {
            font-size: 16px;
            color: #4c4c4c;
            font-weight: 700;
            margin-top: 10px;
          }
        }
      }
      .form-group {
        margin: 10px 0;
        position: relative;
        .form-select,
        .form-control {
          border-color: #000;
          background: #fff;
          color: #000000;
          &::placeholder {
            color: #000000;
          }
          &.timeInput {
            padding-left: 35px;
          }
        }
        .mapIcon {
          display: inline-block;
          width: 20px;
          position: absolute;
          top: 50%;
          left: 15px;
          transform: translateY(-50%);
          i{
            color: #000;
          }
        }
      }
      .descBox{
        padding: 10px 0;
        h6{
          font-size: 14px;
          font-weight: 600;
        }
        .injury_title{
          font-size: 13px;
        }
        .pics_box{
          display: flex;
          img{
            width: 80px;
            height: 65px;
            object-fit: cover;
            border-radius: 5px;
            margin-right: 5px;
            border: 1px solid #fff;
          }
        }
        .descPara{
        }
        p{
          font-size: 13px;
          font-weight: 500;
          color: #3c3d3c;
          margin: 10px 0;
          img{
            width: 14px;
          }
          span{
            font-weight: 600;
          }
        }
        &.reponse{
          background: #1cbb93;
          border-radius: 15px;
          color: #fff;
          padding-left: 20px;
          p{
            color: #e6fcf0;
            font-weight: 400;
            font-size: 12px;
          }
          h6{
            font-weight: 500;
            color: #e6fcf0;
          }
        }
      }
    }
    .textMessage{
      margin-top: 40px;
      padding: 10px;
      .form-group{
          position: relative;
          margin: 0;
          .form-control{
              border-radius: 8px;
              background-color: #f7f8fc;
              font-size: 14px;
              border-color: #d6edf4;
              font-size: 13px;
            &:focus{
              border-color: #d6edf4;
            }
            &::placeholder{
              color: #b5b6ba;
            }
          }
          a{
              display: inline-block;
              position: absolute;
              right: 10px;
              top: 15px;
              line-height: 0;
              i{
                  color: #01b399;
                  font-size: 12px;
              }
          }
      }
    }
  }
}

@media (max-width:991px) {
  .tiket_detail{
    .content{
      .textMessage{
        margin-top: 25px;
      }
    }
    .form-group{
      .form-control{
        min-height: 35px;
      }
    }
  }
}
  
  @media (max-width: 576px) {
    .tiket_detail {
      .content{
        padding: 15px 10px;
        .textMessage{
          padding: 10px 0;
          margin-top: 20px;
        }
        .contentBox{
          padding: 15px 0;
          .descBox{
            &.reponse{
            padding: 15px;
          }
          }
        }
        .form-group{
          margin: 5px 0;
          .desc{
            margin: 5px 0;
          }
        }
      }
    }
  }
  