.edit_profile {
  padding: 50px 0;
  min-height: calc(100vh - 62px);
    display: flex;
    justify-content: center;
    background: #fff;
    // align-items: center;
  .regForm {
    text-align: center;
    background: #fff;
    border: 1px solid #9ad8f1;
    padding: 20px;
    border-radius: 10px;
    .regBox{
      border-bottom: none;
      position: relative;
      h4 {
        color: #000000;
        font-weight: 600;
        color: #000;
        text-align: center;
        font-size: 20px;
        margin-bottom: 0;
      }
      .editDetail {
        position: absolute;
        right: 0;
        top: 0;
        button {
          display: inline-block;
          align-items: center;
          justify-content: center;
          color: #484848;
          font-weight: 500;
        }
      }
    }
    .avatar{
      margin: 25px 0;
      .profileImg{
        text-align: center;
        position: relative;
        background: #f5f5f5;
        width: 75px;
        height: 75px;
        margin: auto;
        border-radius: 50%;
        border: 1px solid #9ad8f1;
        display: flex;
        justify-content: center;
        margin-bottom: 25px;
        align-items: center;
        box-shadow: 0px 0px 5px 3px rgba(88 ,178, 209, 0.15);
        img{
          border-radius: 50%;
        }
        span{
            display: inline-block;
            color: #cecece;
            font-size: 35px;
            i{
              font-size: unset;
            }
        }
        .icon{
          position: absolute;
          right: -10px;
          bottom: 0px;
          .camIcon{
              border: 1px solid #898989;
              width: 30px;
              height: 30px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #000;
            span{
                display: inline-block;
                font-size: 14px;
                line-height: 0;
                i{
                  color: #fff;
                  font-size: 13px;
                }
            }
          }
      }
    }
    h5{
      text-align: left;
      font-size: 16px;
      font-weight: 700;
      position: relative;
      &::after{
        content: '';
        position: absolute;
        width: 50%;
        height: 1px;
        background: #5e5e5e;
        display: inline-block;
        top: 50%;
        left: 50%;
        transform: translateY(-50%, -50%);
      }
    }
    }

    .nextBtn {
      margin: 20px 0 0;
      text-align: center;
      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #000;
        color: #fff;
        border-radius: 10px;
        padding: 8px 10px;
        max-width: 225px;
        width: 100%;
        font-size: 15px;
      }
    }
  }
}
@media (max-width:991px) {
  .edit_profile{
    min-height: calc(100vh - 56px);
  }
}
@media(max-width:576px){
  .edit_profile{
    padding: 35px 0;
    .regForm{
      padding: 25px 10px;
      .nextBtn{
        a{
          max-width: none;
          padding: 8px 50px;
        }
      }
      .avatar{
        margin: 10px 0;
      }
    }
  }
}