.approve_alert {
    padding: 35px 0;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 60px);
  
    .content {
      border-radius: 15px;
      background-color: #fff;
      .title {
        .heading {
          h4{
            text-align: left;
            font-weight: 600;
            color: #000000;
            font-size: 18px;
        }
        }
      }
      .notifyBox{
          border: 1px solid #9ad8f1;
          border-radius: 10px;
          margin: 20px 0;
          padding: 10px;
          h5{
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 16px;
              color: #000;
              font-weight: 600;
              margin-bottom: 10px;
              span{
                display: inline-block;
                font-size: 14px;
                color: #a9a9a9;
                font-weight: 500;
              }
          }
          p{
              font-size: 14px;
              font-weight: 500;
              color: #000;
              margin-bottom: 10px;

          }
          .time{
              text-align: right;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 15px;
              a{
                  display: inline-block;
                  background: #000;
                  color: #fff;
                  padding: 7px 15px;
                  border-radius: 10px;
                  max-width: 150px;
                  width: 100%;
                  text-align: center;
              }
              span{
                  display: inline-block;
                  font-size: 14px;
                  color: #a9a9a9;
                  font-weight: 500;
              }
          }
          &.confirmJob{
              .confirmBtn{
                  text-align: right;
                  a{
                    color: #24bcc9;
                    border-radius: 8px;
                    font-weight: 500;
                    font-size: 13px;
                      i{
                          color: #2f9696;
                          margin-left: 3px;
                      }
                  }
              }
              h6{
                  font-size: 15px;
                  font-weight: 600;
                  color: #000;
              }
              .time{
                  margin-top: 0;
                p{
                    font-size: 13px;
                    font-weight: 600;
                    color: #000;
                    display: flex;
                    align-items: center;
                    margin: 0px;
                    i{
                        color: #000;
                        margin-right: 3px;
                    }
                  }
                  span{
                    display: inline-block;
                    font-size: 14px;
                    color: #a9a9a9;
                    font-weight: 500;
                }
              }
              .approve_btn{
                display: inline-block;
                background: #000;
                color: #fff;
                padding: 7px 15px;
                border-radius: 10px;
                max-width: 150px;
                width: 100%;
                text-align: center;
                margin-top: 15px;
              }
          }
      }
    }
  }

  @media (max-width: 576px){
    .approve_alert{
        .content{
            padding: 0px;
          .location_title{
              .backBtn{
                  font-size: 0px;
              }
          }  
            .notifyBox{
                padding: 10px;
                .time
                {
                    .approveBtn{
                        width: 140px;
                    }
                    a{
                        font-size: 15px;
                        width: 120px;
                    }
                }
                h5{
                    margin-bottom: 5px;
                }
                &.confirmJob{
                    .confirmBtn{
                        text-align: left;
                        margin: 20px 0;
                    }
                }
            }
            .title{
                .heading{
                    h4{
                        text-align: center;
                    }
                }
            }
        }
    }
  }
  
  