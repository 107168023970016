.forgot_page {
  display: flex;
  justify-content: center;
  background: #fff;
  min-height: 100vh;
  padding: 85px 0 25px;
  .regForm {
    text-align: center;
    background: #fff;
    border: 1px solid #9ad8f1;
    border-radius: 10px;
    padding: 35px;
    margin-top: 15px;
    .regBox{
      border-bottom: none;
      position: relative;
      h4 {
        font-weight: 600;
        color: #000;
        text-align: center;
        font-size: 18px;
      }
    }
    .avatar{
      margin: 25px 0;
      p{
          font-weight: 600;
          font-size: 15px;
          margin-bottom: 0;
          text-align: center;
          color: #000;
      }
    }

    .nextBtn {
      margin: 20px 0 0;
      text-align: center;
      padding: 0 20px;
      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #000;
        color: #fff;
        border-radius: 10px;
        padding: 10px 10px;
        width: 100%;
        font-size: 15px;
        i {
          margin-left: 5px;
          color: #fff;
        }
      }
      .back_login{
          background: transparent;
          color: #0a0a0a;
          font-weight: 600;
          text-align: center;
          padding: 0;
          margin-top: 25px;
          align-items: center;
          font-size: 16px;
          span{
            color: #889aaa;
            font-size: 14px;
            font-weight: 500;
            margin-right: 5px;
          }
          i{
              margin-right: 5px;
          }
      }
    }
  }
}
.forgetModal{
    .modal-dialog{
      max-width: 425px;
        .modal-content{
            border-radius: 15px;
            padding: 25px 35px;
            .modal-header{
                text-align: center;
                padding: 0;
                border-bottom: none;
                h5{
                    font-size: 22px;
                    padding-bottom: 25px;
                    border-bottom: 1px solid #a0a0a0;
                }
            }
            .modal-footer{
                border-top: none;
                justify-content: center;
                padding: 0;
                a{
                    margin-top: 25px;
                    display: flex;
                    align-items: center;
                    color: #0a0a0a;
                    font-weight: 600;
                    font-size: 22px;
                    i{
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}
@media (max-width: 1199px){
   .forgot_page{
     .regForm{
       padding: 20px;
     }
   }
}
@media (max-width: 991px){
  .forgot_page{
    .regForm{
      padding: 25px;
    }
  }
}
@media (max-width: 576px){
  .forgot_page{
    .regForm{
      padding: 25px 15px;
      .nextBtn{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0;
        a{
          max-width: none;
        }
      }
      .avatar{
        p{
          font-size: 15px;
        }
      }
    }
  }
}