.signupPage {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background: #fff;
  align-items: center;
  padding: 100px 0 25px;

  .signupForm {
    text-align: center;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    .form-check-input {
      border: 1px solid #000000;
      &:checked {
        background-image: none;
        background: #000000;
      }
      &:focus {
        box-shadow: none;
      }
    }
    .form-check-label {
      font-weight: 600;
      color: #000;
    }
    .signUpBox {
      text-align: left;
      position: relative;
      .backBtn {
        display: inline-block;
        color: #000000;
        font-weight: 900;
        position: absolute;
        left: 0px;
        top: 2px;
      }
      h4 {
        color: #000000;
        font-weight: 600;
        font-size: 22px;
        color: #000;
      }
    }

    .already_Account {
      text-align: center;
      margin-top: 15px;
      span {
        display: inline-block;
        font-weight: 500;
        color: #92a7b1;
        font-size: 15px;
      }
      a {
        display: inline-block;
        justify-content: center;
        align-items: center;
        background: #fff;
        color: #000;
        font-weight: 600;
        span {
          margin-right: 5px;
        }
      }
    }

    .nextBtn {
      text-align: center;
      margin-top: 20px;
      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #000;
        color: #fff;
        border-radius: 10px;
        padding: 10px 8px;
        font-size: 14px;
        width: 100%;
        span {
          margin-left: 5px;
        }
      }
    }

    h5 {
      color: #000000;
      font-weight: 600;
      text-align: center;
      font-size: 16px;
      margin: 35px 0 5px;
    }
  }

  // .editDetail {
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   display: flex;
  //   align-items: center;
  //   background: #000;
  //   border-radius: 5px;

  // }
  .backBtn {
    background: black;
    color: white;
    border-radius: 10px;
    border: 2px solid black;
    padding: 0px 4px;
  }

  .form-group {
    position: relative;
    margin-top: 10px;
    .form-control {
      border-radius: 10px;
      padding-right: 35px;
      min-height: 45px;
      font-weight: 400;
      border-color: #d6edf4;
      background: #f7f8fc;
      font-size: 15px;
      &:focus {
        border-color: #d6edf4;
      }
    }
    span {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      color: #676767;
      cursor: pointer;
    }
    select.form-control {
      background: #fff;
      border: 1px solid #000;
      font-weight: 500;
    }
    &.date {
      span {
        z-index: -1;
      }
    }
  }
}

@media (max-width: 1199px) {
  .signupPage {
    padding: 60px 0 0px;
    .signupForm {
      padding: 35px 15px;
      h5 {
        font-size: 17px;
      }
      .nextBtn {
        button {
          margin: 35px 0 0;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .signupPage {
    padding: 50px 0 0px;
    .signupForm {
      margin: 10px 0 0;
      padding: 25px 0px;
      h5 {
        margin: 20px 0px 5px;
      }
      .nextBtn {
        button {
          margin: 15px 0 0;
        }
      }
    }
  }
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
