.ongoing_job {
  padding: 50px 0;
  background: #f5f5f5;
  min-height: 100%;

  .title {
    border-radius: 20px;
    background-color: #fff;
    padding: 20px 35px;
    box-shadow: 0 0 10px 1px rgba(133, 133, 133, 0.1);
    margin-bottom: 30px;

    h4 {
      font-weight: 600;
      font-size: 20px;
      margin: 0px;
    }

    .content {
      h5 {
        font-weight: 600;
        font-size: 18px;
        margin: 5px 0 0;
      }

      p {
        margin: 7px 0;
        font-size: 16px;

        span {
          font-weight: 700;
        }
      }
    }

    .icon {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-top: 5px;

      a {
        margin-left: 10px;
        display: inline-block;
      }
    }

    .mike {
      display: flex;

      p {
        margin: 5px 10px 0 0;
        font-weight: 600;

        span {
          margin-right: 5px;
        }
      }
    }
  }

  .dateBox {
    border-radius: 20px;
    background-color: #fff;
    padding: 20px 35px;
    box-shadow: 0 0 10px 1px rgba(133, 133, 133, 0.1);

    h4 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 25px;
    }

    .tabBtn {
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;

      a {
        display: inline-block;
        background: #000;
        color: #fff;
        padding: 7px 15px;
        border-radius: 10px;
        max-width: 150px;
        width: 100%;
        text-align: center;
        margin-top: 15px;
        // &:nth-child(2){
        //     margin: 0 8px;
        // }
      }
    }

    .form-group {
      border: 1px solid #676767;
      border-radius: 25px;
      align-items: center;
      margin: 8px 0;

      .form-control {
        margin-bottom: 0;
        border: none;
      }

      select {
        font-weight: 400;
      }
    }
  }

  .form-group {
    position: relative;

    .form-control,
    .form-select {
      border-radius: 25px;
      border: none;
      padding-right: 35px;
      min-height: 50px;
      color: #000;
      font-weight: 500;
      border: 1px solid #919191;

      &:focus {
        border-color: #919191;
      }

      &::placeholder {
        color: #000;
        font-weight: 500;
      }
    }

    span {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      color: #676767;
    }

    select {
      margin-bottom: 15px;
      color: #000;
      font-weight: 400;
    }
  }

  .shiftBox {
    border: 1px solid #919191;
    border-radius: 10px;
    padding: 25px;
    margin-top: 15px;

    p {
      font-weight: 500;
    }

    .shift_title {
      margin-bottom: 10px;

      h4 {
        font-weight: 600;
        font-size: 22px;
        margin-bottom: 0px;
      }

      .editProBtn {
        text-align: right;
        margin-bottom: 0px;

        a {
          display: inline-block;
          color: #4c4c4c;
          font-weight: 600;
          font-size: 16px;

          span {
            margin-right: 3px;
          }
        }
      }
    }

    h5 {
      span {
        margin-right: 5px;
      }
    }

    .date_time {
      text-align: right;
    }

    .form-group {
      .on_going_shiftBox {
        display: flex;

        .ongoing_img_label {
          display: flex;
          align-items: center;
          flex: 1;
          margin-left: 5px;

          .imgBox_ongoing {
            width: 30px;
            min-width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-right: 5px;

            img{
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
        }

        .form-control{
          padding: 0 15px 0 10px;
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .ongoing_job {
    .title {
      padding: 15px 20px;
    }
  }
}

@media (max-width: 991px) {
  .ongoing_job {
    .dateBox {
      .form-group {
        label {
          font-size: 13px;
        }

        .form-control {
          font-size: 14px;
        }
      }
    }

    .shiftBox {
      h5 {
        font-size: 20px;
      }

      .shift_title {
        h4 {
          font-size: 20px;
        }
      }
    }
  }

}

@media (max-width: 576px) {
  .ongoing_job {
    .dateBox {
      .form-group {
        label {
          font-size: 16px;
        }

        .form-control {
          font-size: 16px;
        }
      }

      padding: 20px 15px;

      .tabBtn {
        a {
          font-size: 13px;
        }
      }
    }

    .title {
      h4 {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 375px){
  .ongoing_job {
    .shiftBox{
      padding: 15px 6px;
    }
  }
}

@media (max-width: 374px){
  .ongoing_job{
    .dateBox {
      .tabBtn{
        a{
          max-width: 115px;
        }
      }
    }
  }
}

.approve_btn {
  display: inline-block;
  background: #000;
  color: #fff;
  padding: 7px 15px;
  border-radius: 10px;
  max-width: 150px;
  width: 100%;
  text-align: center;
  margin-top: 15px;
}

.guard-imgBox {
  min-width: 100px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 5px 0 0;

  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }
}