.add_recurring_job {
  min-height: 100vh;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0 25px;

  .signupForm {
    text-align: center;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    .job_title {
      text-align: left;
      margin-bottom: 25px;
      position: relative;
      .backBtn {
        display: inline-block;
        color: #000000;
        font-weight: 900;
        position: absolute;
        left: 0px;
        top: 2px;
        span{
            font-size: 20px;
        }
      }
      h4 {
        color: #000000;
        font-weight: 600;
        font-size: 20px;
        color: #000;
        margin-bottom: 0;
      }
    }
    .form-group {
      position: relative;
      select{
          margin-bottom: 15px;
      }
    }
    .nextBtn {
      text-align: center;
      margin-top: 20px;
      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #000;
        color: #fff;
        border-radius: 10px;
        padding: 10px 8px;
        display: block;
        width: auto;
        span {
          margin-left: 5px;
        }
      }
    }
    .guard_title{
      margin: 15px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #d5d5d5;
      padding-top: 15px;
      h5{
      text-align: left;
      font-size: 16px;
      font-weight: 600;
      flex: 1;
      margin-bottom: 0;
    }
    a{
      display: inline-block;
      position: relative;
      flex: 1;
      text-align: right;
      color: #676767;
      font-weight: 600;
      span{
          color: #000;
          margin-right: 5px;
          i{
            color: #000;
          }
      }
    }
  }
  .title_inner{
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    }
  }
}

@media (max-width: 1199px) {
  .add_recurring_job {
    padding: 70px 0px 0px;
    .form-group{
      .form-control{
        font-size: 13px;
      }
    }
    .signupForm {
      padding: 35px 20px;
      .job_title{
        .backBtn{
          top: 0px;
        }
      }
      .nextBtn {
        button {
          margin: 35px 0 0;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .add_recurring_job {
    padding: 40px 0px 0px;
    .signupForm {
      margin: 20px 0 0;
      padding: 25px 0px;
      .job_title{
        .backBtn{
          top: -4px;
        }
      }
      .guard_title{
        a{
          .add-span{
            display: none;
          }
          &::before{
            width: calc(100% - 30px);
          }
        }
      }
      .nextBtn {
        a{
          width: 100%;
          margin: 10px auto 0px;
        }
        button {
          margin: 15px 0 0;
        }
      }
    }
  }
}
