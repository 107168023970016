.add_business {
  display: flex;
  justify-content: center;
  background: #fff;
  min-height: 100vh;
  padding: 100px 0 25px;
  .regForm {
    text-align: center;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    .regBox{
      border-bottom: none;
      position: relative;
      .location_title {
          text-align: left;
          margin-bottom: 25px;
          position: relative;
          // .backBtn {
          //   display: inline-block;
          //   color: #000000;
          //   font-weight: 900;
          //   position: absolute;
          //   left: 0px;
          //   top: 0px;
          //   span{
          //       font-size: 20px;
          //   }
          // }
          h4 {
            color: #000000;
            font-weight: 600;
            font-size: 22px;
            color: #000;
            margin-bottom: 0;
          }

          .editDetail {
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            align-items: center;
            background: #000;
            border-radius: 5px;
            a {
              display: inline-block;
              align-items: center;
              justify-content: center;
              color: #fff;
              font-weight: 500;
              font-size: 11px;
              padding: 5px 8px;
              span{
                margin-left: 5px;
              }
              i{
                color: #fff;
              }
            }

            &.business-loca-edit-btn{
              position: static;
            }
          }
        }
    }
    .avatar{
      margin: 15px 0;
      border-bottom: 1px solid #ccc;
      padding-bottom: 10px;
      .profileImg{
          border: 1px solid #5e5e5e;
          box-shadow: 0 0 10px rgba(201,201,201,0.5);
          border-radius: 10px;
          text-align: left;
          padding: 20px 6px 20px 0;
          h4{
              margin: 0;
              font-size: 18px;
              font-weight: 600;
          }
          p{
              margin:6px 0 0 0;
              font-size: 16px;
              color: #676767;
              font-weight: 500;
              display: flex;
              align-items: center;
              span{
                  margin-right: 5px;
                  font-size: 14px;
              }
          }
          .icon{
              span{
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 55px;
                  height: 55px;
                  border: 1px solid #8595b1;
                  border-radius: 50%;
                  margin: auto;
                  i{
                      font-size: 18px;
                      color: #8595b1;
                  }
              }
          }
          .business_pro_content{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            i{
              font-size: 15px;
              color: #000;
            }
            h4{
              font-size: 13px;
            }
            p{
              font-size: 14px;
              margin:0 0 0 15px;
              color: #000;
              font-weight: 600;
            }
            span{
              line-height: 0;
            }
          }
      }
      .head_title{
          text-align: left;
          font-weight: 600;
          font-size: 16px;
          color: #000;
          margin: 25px 0;
      }
    }
    .identityBox{
      .identityTitle{
        position: relative;
        margin-bottom: 20px;
        h5{
          text-align: left;
          font-weight: 600;
          font-size: 16px;
          color: #000;
          margin-bottom: 0;
        }
        .editDetail{
          position: absolute;
          top: 0px;
          right: 0;
          line-height: 0;
          a{
            color: #000;
            display: inline-block;
            font-weight: 600;
            padding: 0;
            text-align: right;
            font-size: 14px;
            i{
              color: #000;
            }
          }

          &.business-loca-edit-btn{
            position: static;
            margin-top: 20px;
            text-align: end;
          }
        }
      }
    }

    .nextBtn {
      margin: 20px 0 0;
      text-align: center;
      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #000;
        color: #fff;
        border-radius: 10px;
        padding: 8px 10px;
        max-width: 300px;
        font-size: 15px;
        width: 100%;
        span {
          margin-left: 5px;
        }
      }
    }
  }
}
@media (max-width: 1199px){
  .add_business{
    .regForm{
        padding: 0px 25px;
        .avatar{
          .profileImg{
            padding: 20px 6px 20px 20px;
          }
        }
    }
  }
}
@media (max-width: 576px){
  .add_business{
    padding: 85px 0 25px;
    .regForm{
      padding: 0;
      .regBox{
        .location_title{
          h4{
            font-size: 21px;
          }
          .editDetail{
            a{
              padding: 6px 12px;
              line-height: 0;
              span{
                display: none;
              }
            }
          }
        }
      }
      .avatar{
        .head_title{
          margin : 18px 0;
        }
        .profileImg{
          padding: 10px;
          p{
            font-size: 14px;
          }
          .business_pro_content{
            flex-direction: column;
            justify-content: start;
            align-items: flex-start;
            margin-top: 10px;
            p{
              margin: 0;
            }
          }
        }
      }
      .identityBox{
        .identityTitle{
          margin-bottom: 10px;
          .editDetail{
            a{
              .add-span{
                // display: none;
              }
            }

            &.business-loca-edit-btn{
              margin-top: 10px;
            }
          }
        }
      }
      .nextBtn{
        a{
          max-width: none;
        }
      }
    }
  }
  .notiFi{
    h6{
      text-align: left;
      padding-right: 10px;
    }
  }
}


@media (max-width: 375px){
  .add_business{
    .regForm{
      .regBox{
        .location_title{
          h4{
            font-size: 17px;
          }
        }
      }
    }
  }
}

.update_files{
  border: 1px solid;
  display: block;
  text-align: center;
  border-radius: 10px;
  background: #f7f8fc;
  border-color: #ddecf0;
  border-width: 2px;
  color: #292a2e;
  padding-right: 30px;
  min-height: 40px;
  position: relative;
  line-height: 40px;
  cursor: pointer;
}
.notiFi {
  display: flex;
  justify-content: space-between;
  border: 1px solid #d6edf4;
  border-radius: 10px;
  padding: 1.5rem 1rem;

  h6{
    font-weight: 600;
    color: #000;
    font-size: 16px;
  }
  // .toggle-button-cover {
  //   position: relative;
  // }
  .button-cover {
    background-color: #fff;
    box-shadow: 0 10px 20px -8px #c5d6d6;
    border-radius: 4px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .knobs {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
  .layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #ebf7fc;
    transition: 0.3s ease all;
    z-index: 1;
  }
  .button {
    position: relative;
    top: 50%;
    width: 77px;
    min-width: 77px;
    height: 28px;
    margin: 0 0 0 auto;
    overflow: hidden;
  }
  .button.r {
    border-radius: 30px;
    border: 1px solid #d6edf4;
    .layer {
      border-radius: 30px;
    }
  }
  .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }
  #button-1 {
    .knobs {
      &:before {
        content: "Later";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 44px;
        height: 26px;
        color: #fff;
        font-size: 10px;
        font-weight: bold;
        text-align: center;
        line-height: 1;
        line-height: 26px;
        background: #a8deef;
        border-radius: 30px;
        transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
        transition: 0.3s ease all;
      }
      transition: 0.3s ease all;
    }
    .checkbox {
      &:checked {
        + {
          .knobs {
            &:before {
              content: "Now";
              left: 34px;
              background: #53acb4;
            }
          }
        }
        ~ {
          .layer {
            background-color: #e7e7e7;
          }
        }
      }
    }
    .layer {
      transition: 0.3s ease all;
    }
  }
}