.otp_page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #fff;
  .regForm {
    text-align: center;
    background: #fff;
    border: 1px solid #9ad8f1;
    border-radius: 10px;
    padding: 35px;
    .regBox{
      border-bottom: none;
      position: relative;
      h4 {
        color: #000000;
        font-weight: 600;
        color: #000;
        text-align: center;
        font-size: 18px;
      }
    }
    .avatar{
      margin: 25px 0;
      p{
          font-weight: 600;
          font-size: 15px;
          margin-bottom: 0;
          text-align: center;
          color: #000;
      }
    }

    .nextBtn {
      margin: 20px 0 0;
      text-align: center;
      padding: 0 20px;
      a {
        width: auto;
        align-items: center;
        justify-content: center;
        background: #000;
        color: #fff;
        border-radius: 10px;
        padding: 8px 10px;
        display: block;
        font-size: 15px;
        i {
          margin-left: 5px;
        }
      }
      .resend_btn{
        margin-top: 25px;
          background: transparent;
          color: #0a0a0a !important;
          font-weight: 600;
          display: inline-block;
          font-size: 15px;
      }
    }
  }
}
@media (max-width:1199px) {
  .otp_page{
    .regForm{
      padding: 20px;
    }
  }
}
@media (max-width:991px) {
  .otp_page{
    .regForm{
      padding: 25px;
    }
  }
}

@media (max-width: 576px){
  .otp_page{
    .regForm{
      padding: 20px;
      .nextBtn{
        padding: 0;
        a{
          width: 100%;
          margin: auto;
        }
      }
    }
  }
}
