.previous_ticket {
    padding: 50px 0;
    background: #f5f5f5;
    min-height: calc(100vh - 75px);
    display: flex;
    align-items: center;
  
    .content {
      border-radius: 15px;
      box-shadow: 0px 0px 8px #e5e5e5, 5px 5px 8px #e9e9e9;
      padding: 25px;
      background-color: #fff;
      .title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        position: relative;
        .backArrow {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          a {
            border: 0;
            outline: none;
            background: none;
            color: #000;
          }
        }
        .heading {
          width: 100%;
          text-align: center;
          h1 {
            font-size: 20px;
            font-weight: 700;
          }
        }
      }
      .descBox {
        margin: 10px 0;
        border: 1px solid #d7d7d7;
        padding: 10px;
        border-radius: 6px;
        h6 {
          font-size: 18px;
          font-weight: 700;
          color: #020202;
        }
        p {
          font-size: 15px;
          color: #020202;
          font-weight: 500;
          display: flex;
          align-items: center;
          margin: 10px 0;
          svg {
            fill: #4d4d4d;
          }
          span {
            font-weight: 600;
            color: #020202;
            font-size: 15px;
            margin-right: 5px;
          }
        }
        .pdfFile {
          display: flex;
          align-items: center;
          img {
            margin-right: 5px;
          }
        }
      }
    }
  }
  @media (max-width: 1199px){
    .previous_ticket {
      .content{
        .descBox{
          padding: 10px 20px;
        }
      }
    }
  }
  @media (max-width: 991px){
    .previous_ticket {
      .content{
        .descBox{
          padding: 10px;
        }
      }
    }
  }
  
  @media (max-width: 576px){
    .previous_ticket {
      .content{
        .descBox{
          padding: 10px 20px;
        }
        padding: 25px 10px;
      }
    }
  }
  