.homeBanner {
  min-height: calc(100vh - 55px);
  padding: 100px 0 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  .Box {
    .content {
      padding: 75px 15px;
      border-radius: 10px;
      text-align: center;
      h3 {
        font-size: 18px;
        color: #1b1b1b;
        font-weight: 600;
      }
      .proceedLeft {
        display: inline-block;
        border-radius: 8px;
        padding: 7px 35px;
        background: #000;
        color: #fff;
        font-size: 14px;
        margin: 35px auto 0;
        .left,.right{
          margin-left: 5px;
          font-weight: 900;
          i{
            background: none;
            color: #fff;
            -webkit-background-clip: unset;
            -webkit-text-fill-color: unset;
          }
        }
        .right{
          margin-left: 5px;
          i{
            color: #000;
          }
        }
      }
      &.lightBg {
        background: #fff;
        color: #000;
        border: 1px solid #fff;
      }
      &.darkBg {
        background: transparent;
        color: #fff;
        border: 1px solid #fff;
        h3 {
          color: #fff;
        }
        .proceedLeft {
          background: #fff;
          color: #000000;
        }
      }
    }
  }
}
@media (max-width:1399px) {
  .homeBanner{
    .Box{
      .content{
        padding: 70px 15px;
      }
    }
  }
}
@media (max-width:1199px) {
  .homeBanner{
    .Box{
      .content{
        padding: 65px 15px;
      }
    }
  }
}
@media (max-width: 991px){
  .homeBanner{
    padding: 70px 0 25px;
    .Box{
      margin: 30px 0px;
      .content{
        &.lightBg {
          margin-bottom: 30px;
        }
      }
    }
  }
}

@media (max-width: 576px){
  .homeBanner{
    padding: 50px 0px 0px;
    .Box{
      margin-top: 50px;
      .content{
        padding: 35px 0px;
        &.lightBg{
          margin-bottom: 20px;
        }
        h3{
          font-size: 16px;
        }
        .proceedLeft{
          padding: 6px 25px;
        }
      }
    }
  }
}
