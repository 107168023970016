.tracker_awrded_job {
    padding: 25px 0;
    background: #fff;
    min-height: calc(100vh - 62px);
  
    .dateFormat{
      display: flex;
      justify-content: flex-end;
    }
  
    .form-group {
      position: relative;
      .form-control {
        background-color: #fff;
        padding-right: 0.75rem;
        font-size: 14px;
        min-height: 40px;
        font-weight: 500;
        &:focus {
          border-color: #d6edf4;
        }
      }
    }
  
    .left_sidebar{
      border: 1px solid #d6edf4;
      border-bottom: none;
      border-radius: 10px;
      text-align: left;
      h5{
        font-size: 17px;
        color: #000;
        font-weight: 600;
        border-bottom: 1px solid #d6edf4;
        padding: 8px 10px;
      }
      .nav_box{
        .nav-link{
          text-align: left;
          padding: 5px 10px;
          font-weight: 500;
          width: 100%;
          border-radius: 0;
          color: #000;
          font-size: 14px;
          border-bottom: 1px solid #d6edf4;
          span{
            display: block;
            color: #727272;
            font-size: 13px;
            display: flex;
            align-items: center;
            i{
              margin-right: 5px;
            }
          }
          &.active{
            background: #f7f8fc;
            color: #000;
          }
        }
      }
    }
    .content{
      padding: 5px 15px;
      .awrded_job_title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        color: #000;
        font-weight: 600;
        span{
          font-size: 13px;
          font-weight: 500;
        }
      }
      p{
        margin: 8px 0;
      }
      .adderess {
        font-size: 14px;
        color: #565656;
        font-weight: 500;
        margin-bottom: 0;
      }
      .desc {
        display: flex;
        .flexBox {
          p {
            font-size: 14px;
            color: #161616;
            font-weight: 500;
            span {
              display: inline-block;
              font-weight: 600;
              color: #000;
            }
          }
          &.spaceRight {
            margin-left: 70px;
          }
        }
      }
      .mapBox{
        margin-top: 15px;
      }
    }
    .reach_box{
      border: 1px solid #9ad8f1;
      padding:15px 20px;
      border-radius: 10px;
      margin-bottom: 10px;
      .shift_title{
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        h6{
          color: #000;
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 0px;
        }
        span{
          display: inline-block;
          font-size: 13px;
          color: #565656;
          font-weight: 500;
        }
      }
      .box{
        display: flex;
        align-items: center;
        border: 1px solid #9ad8f1;
        border-radius: 35px;
        overflow: hidden;
        padding: 0 10px;
        margin-top: 10px;
        height: 40px;
        .col-form-label{
          padding: 0;
          font-size: 14px;
        }
        .form-group {
          position: relative;
          margin-bottom: 0;
          flex: 1;
          .form-select {
            border: none;
            color: #383838;
            font-weight: 500;
            margin-bottom: 0;
            font-size: 14px;
            padding-top: 0;
            padding-bottom: 0;
          }
        }
      }
    }
  }
  
  @media (max-width: 1199px) {
    .tracker_awrded_job {
      .content{
        padding: 5px 0;
        .desc{
          .flexBox{
            p{
              font-size: 13px;
            }
          }
        }
      }
      .title {
        padding: 15px;
      }
      .left_sidebar{
        .nav_box{
          .nav-link{
            span{
              align-items: baseline;
            }
          }
        }
      }
    }
  }
  @media (max-width: 576px){
    .tracker_awrded_job{
      .left_sidebar{
        .nav_box{
          .nav-link{
            span{
              i{
                margin-right: 8px;
              }
            }
          }
        }
      }
      .content{
        padding: 15px 0px;
        margin: 10px 0;
      }
      .dateBox{
        padding: 20px 15px;
      }
      .title {
        h4{
          font-size: 18px;
        }
        .mike{
          p{
            font-size: 13px;
          }
        }
      }
      .shiftBox{
        padding: 15px 8px;
      }
      .dateFormat{
        justify-content: center;
      }
      }
  }

  .center{
    text-align: center;
  }

  .nodata_img{
    text-align: center;
    margin-top: 85px;
    img{
      width: 250px;
    }
  }
    