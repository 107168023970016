.sos {
    min-height: calc(100vh - 75px);
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
  
    .sosBox {
      text-align: center;
      margin: 25px 0;
      background: #fff;
      border: 1px solid #9ad8f1;
      border-radius: 10px;
      padding: 35px;
      h4 {
        color: #000000;
        font-weight: 600;
        font-size: 22px;
        color: #000;
        margin-bottom: 15px;
      }
      .mapBox{
          margin-bottom: 15px;
      }
      .content{
          text-align: left;
          width: 100%;
          margin: 15px auto;
          p{
              font-weight: 500;
              margin-bottom: 12px;
              font-size: 16px;
              .span-name{
                  display: inline-block;
                  font-weight: 600;
                  margin-right: 5px;
                  font-size: 17px;
                  min-width: 120px;
              }
              .dot{
                  margin-right: 10px;
              }
          }
      }
      .nextBtn {
        text-align: center;
        margin-top: 20px;
        a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(#a0a0a0, #323232);
          color: #fff;
          border-radius: 25px;
          padding: 10px 50px;
          max-width: 250px;
          width: 100%;
          font-weight: 500;
        }
      }
    }
  }
  
  @media (max-width: 1199px) {
    .sos {
      .sosBox {
        padding: 25px;
        .content{
          p{
            font-size: 15px;
            .span-name{
              font-size: 16px;
              min-width: 110px;
            }
          }
        }
        .nextBtn {
          button {
            margin: 35px 0 0;
          }
        }
      }
    }
  }
  
  @media (max-width: 576px) {
    .sos {
      padding: 35px 0 25px;
      .sosBox {
        margin: 0;
        padding: 15px 10px 0;
        .content{
          p{
            font-size: 14px;
            .dot{
              margin-right: 1px;
            }
            .span-name{
              font-size: 14px;
              min-width: 95px;
            }
          }
        }
        .nextBtn {
          button {
            margin: 15px 0 0;
          }
        }
      }
    }
  }
  