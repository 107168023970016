.add_unit {
  display: flex;
  justify-content: center;
  background: #fff;
  min-height: 100vh;
  padding: 100px 0 25px;
  .regForm {
    text-align: center;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    .regBox {
      border-bottom: none;
      position: relative;
      .location_title {
        text-align: left;
        margin-bottom: 25px;
        position: relative;
        // .backBtn {
        //   display: inline-block;
        //   color: #000000;
        //   font-weight: 900;
        //   position: absolute;
        //   left: 0px;
        //   top: 0px;
        //   span{
        //       font-size: 20px;
        //   }
        // }
        h4 {
          color: #000000;
          font-weight: 600;
          font-size: 22px;
          color: #000;
          margin-bottom: 0;
        }
        .editDetail {
          position: absolute;
          left: 0;
          top: 0;
          display: flex;
          align-items: center;
          background: #000;
          border-radius: 5px;
          a {
            display: inline-block;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-weight: 500;
            font-size: 11px;
            padding: 5px 8px;
            span {
              margin-left: 5px;
            }
            i {
              color: #fff;
            }
          }
        }
      }
    }
    .avatar {
      margin: 15px 0;
      border-bottom: 1px solid #ccc;
      padding-bottom: 10px;
      .profileImg {
        border: 1px solid #5e5e5e;
        box-shadow: 0 0 10px rgba(201, 201, 201, 0.5);
        border-radius: 10px;
        text-align: left;
        padding: 20px 6px 20px 0;
        h4 {
          margin: 0;
          font-size: 18px;
          font-weight: 600;
        }
        p {
          margin: 6px 0 0 0;
          font-size: 16px;
          color: #676767;
          font-weight: 500;
          display: flex;
          align-items: center;
          span {
            margin-right: 5px;
            font-size: 14px;
          }
        }
        .icon {
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 55px;
            height: 55px;
            border: 1px solid #8595b1;
            border-radius: 50%;
            margin: auto;
            i {
              font-size: 18px;
              color: #8595b1;
            }
          }
        }
        .business_pro_content {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          i {
            font-size: 15px;
            color: #000;
          }
          h4 {
            font-size: 13px;
          }
          p {
            font-size: 14px;
            margin: 0 0 0 15px;
            color: #000;
            font-weight: 600;
          }
          span {
            line-height: 0;
          }
        }
      }
      .head_title {
        text-align: left;
        font-weight: 600;
        font-size: 16px;
        color: #000;
        margin: 25px 0;
      }
    }
    .identityBox {
      .identityTitle {
        position: relative;
        margin-bottom: 20px;
        h5 {
          text-align: left;
          font-weight: 600;
          font-size: 16px;
          color: #000;
          margin-bottom: 0;
          margin-right: 25px;

        }
        .editDetail {
          position: absolute;
          top: 0;
          right: 0;
          line-height: 0;
          a {
            color: #000;
            display: inline-block;
            font-weight: 600;
            padding: 0px;
            text-align: right;
            font-size: 14px;
            i {
              color: #000;
            }
          }
        }
      }
    }

    .nextBtn {
      margin: 20px 0 0;
      text-align: center;
      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #000;
        color: #fff;
        border-radius: 10px;
        padding: 8px 10px;
        max-width: 300px;
        font-size: 15px;
        width: 100%;
        span {
          margin-left: 5px;
        }
      }
    }
    .skipBtn:hover {
      color: white !important;
      content: "Completed!" !important;
    }

    .skipBtn {
      // margin: 20px 0 0;
      text-align: center;
      position: absolute;
      right: 0;
      top: 0;
      a {
        background: #000;
        color: #fff;
        border-radius: 4px;
        padding: 8px 10px;
        /* max-width: 300px; */
        font-size: 15px;
        /* width: 100%; */
        font-size: 11px;
        padding: 5px 40px;
        display: inline-block;
        span {
          margin-right: 5px;
        }
      }
    }
  }
}
@media (max-width: 1199px) {
  .add_unit {
  }
}
@media (max-width: 991px) {
  .add_unit {
    .regForm {
      .avatar {
        .profileImg {
          padding: 20px 10px 20px 15px;
        }
      }
    }
  }
}
@media (max-width: 576px) {
  .add_unit {
    padding: 55px 0 25px;
    .regForm {
      padding: 35px 0;
      .nextBtn {
        a {
          width: 75%;
        }
      }
      .avatar {
        .profileImg {
          padding: 10px;
          p {
            font-size: 14px;
          }
          .business_pro_content {
            flex-direction: column;
            justify-content: start;
            align-items: flex-start;
            margin-top: 10px;
            p {
              margin: 0;
            }
          }
        }
      }
      .identityBox {
        .identityTitle {
          .editDetail {
            a {
              .add-span {
                display: none;
              }
            }
          }
        }
      }
    }
    .regBox {
      .location_title {
        .editDetail,
        .skipBtn {
          a {
            padding: 6px 12px !important;
            line-height: 0;
            span {
              display: none;
            }
          }
        }
      }
    }
  }
}
